import * as service from "../services/games.service";

// Definition of the actions name

export const ACTIONS = {
  BETCREATOR_SESSIONS_SELECTED: 'BETCREATOR_SESSIONS_SELECTED', // doublon avec GAMES_CURRENT_BETTYPE ? 
  BETCREATOR_BETTYPE_SELECTED: 'BETCREATOR_BETTYPE_SELECTED', // doublon avec GAMES_CURRENT_BETTYPE ? 
  BETCREATOR_NUMBERS_SELECTED: 'BETCREATOR_NUMBERS_SELECTED',
  BETCREATOR_STAKE_SELECTED: 'BETCREATOR_STAKE_SELECTED',
  BETCREATOR_CONSECUTIVE_DRAWS: 'BETCREATOR_CONSECUTIVE_DRAWS',
  BETCREATOR_RESET: 'BETCREATOR_RESET',
}
// LIFECYCLE


// SETTERS
export const set_sessions_selected = (sessions) => {
  return { type: ACTIONS.BETCREATOR_SESSIONS_SELECTED, payload: sessions };
}
export const set_betType_selected = (betType) => {
  return { type: ACTIONS.BETCREATOR_BETTYPE_SELECTED, payload: betType };
}
export const set_numbers_selected = (group, numbers) => {
  return { type: ACTIONS.BETCREATOR_NUMBERS_SELECTED, payload: {group, numbers} };
}
export const set_stake_selected = (stake) => {
  return { type: ACTIONS.BETCREATOR_NUMBERS_SELECTED, payload: {stake} };
}
export const set_consecutive_draws = (count) => {
  return { type: ACTIONS.BETCREATOR_CONSECUTIVE_DRAWS, payload: {count} };
}

export const reset_data = (count) => {
  return { type: ACTIONS.BETCREATOR_RESET };
}

// no api calls, betTypes and sessions are managed by the games store
