import React, { useEffect, useState } from 'react';
import {  View, Text, Pressable } from 'react-native';
import {
  SVGLess,
  SVGAdd
} from '../../assets/icons';

export default function BetWithoutAdditionnal(props) {

  const {
    selectedBetType,
    styles,
    playerInfos,
    manipulatePlayerInfos,
    erasePlayerInfos,
    t
  } = props;

  //====================================== Functions

  function isDisabled(grid, numberPress) {
    return playerInfos[grid.gridName].requiredNumber === playerInfos[grid.gridName].playerNumber.length
      && playerInfos[grid.gridName].maxAdditional === playerInfos[grid.gridName].playerNumberAdditional.length
      && !numberPress;
  }
  
  function computeNumberPickClass(grid, numb, gridIndex) {
    if (gridIndex % 2 !== 0 && playerInfos[grid.gridName].playerNumber.includes(numb)) {
      return styles.oddPickNumberPressed;
    } else if (playerInfos[grid.gridName].playerNumber.includes(numb)) {
      return styles.pickNumberPressed;
    } else {
      return styles.pickNumber;
    }
  }

  function computeNumberTextClass(grid, numb, gridIndex) {
    if (
      (gridIndex % 2 !== 0 && playerInfos[grid.gridName].playerNumber.includes(numb))
      || playerInfos[grid.gridName].playerNumberAdditional.includes(numb)
      || playerInfos[grid.gridName].playerNumber.includes(numb)) {
      return styles.numberPressed;
    } else {
      return styles.number;
    }
  }

  return (
    <View>
      {selectedBetType?.listOfGrids.map((grid, gridIndex) => {
        return (
          <View key={gridIndex}>
            <Text style={styles.stepTitle}>
              {t('Select')} {selectedBetType.listOfGridParameters[gridIndex].requiredNumber} {t('numbers')}
            </Text>

            <View style={styles.grid}>
              {selectedBetType?.listOfGrids.length > 1 && 
                <Text style={styles.gridTitle}>
                  {grid.gridName}
                </Text>              
              }
              <Text style={styles.restTitle}>
                {t('Remaining')}: {selectedBetType.listOfGridParameters[gridIndex].requiredNumber - playerInfos[grid.gridName].playerNumber.length}
              </Text>

              <View style={styles.gridPicker}>  
                {grid.listOfLottoNumbers.map((numb, index) => {
                  const [numberPress, setNumberPress] = useState(false);
                  return (
                    <Pressable
                      key={index}
                      onPress={() => {
                        setNumberPress(!numberPress);
                        manipulatePlayerInfos(numb, grid.gridName);
                      }}
                      disabled={isDisabled(grid, numberPress)}
                      style={computeNumberPickClass(grid, numb, gridIndex)}
                    >
                      <Text style={computeNumberTextClass(grid, numb, gridIndex)}>
                        {numb}
                      </Text>
                    </Pressable>
                  )
                })}
              </View>

              {playerInfos[grid.gridName].requiredNumber > 1 && 
                (
                  <Pressable 
                    onPress={() => {
                      erasePlayerInfos(grid.gridName);
                    }}
                    style={styles.eraseButton}
                  >
                    <Text style={styles.textEraseButton}>
                    {t('Delete')}
                    </Text>
                  </Pressable>
              )}
            </View>
          </View>              
        )
      })}
    </View>
  );
}