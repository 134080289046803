import React from 'react';
import { View, Text, StyleSheet, ImageBackground, Pressable } from 'react-native';

const style = StyleSheet.create({
  button: {
    borderRadius: 10,
    backgroundColor: "#2655F9",
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    borderRadius: 6,
    elevation: 3,
    paddingHorizontal: 15,
  },
  text: {
    lineHeight: 15,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white',
    fontFamily: "Raleway-Regular"
  },
});

const KpButton = props => {
  return (
    <Pressable style={style.button}>
        <Text style={style.text}>
            {props.title}
        </Text>
    </Pressable>
  );
};

export default KpButton;