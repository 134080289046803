import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Modal, View, Text, StyleSheet } from 'react-native';

// import { RNCamera } from 'react-native-camera'; //TODO:EXPO
import { Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import ScanDialog from '../../components/scan/ScanDialog.comp';
import * as EBulletionActions from '../../store/actions/ebulletin.actions';
import * as TicketActions from '../../store/actions/ticket.actions';
import { isEbulletinFormat } from '../../store/services/ebulletin.service';



const ScanPreviewPage = ({ route }) => {

  const navigation = useNavigation();
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();
  const style = makeStyles(useTheme())

  const [errorMessage, setErrorMessage] = useState('');
  const [scanTimer, setScanTimer] = useState(null);
  const [scanEntry, setScanEntry] = useState(null);

  const errorBulletin = useSelector(state => state.ebulletin.error);
  const errorTicket = useSelector(state => state.ticket.error);
  const isFetching = useSelector(state => state.ebulletin.isFetching || state.ticket.isFetching);

  const SCAN_TIMEOUT = 30000;
  const scanTimerRef = useRef(scanTimer);
  scanTimerRef.current = scanTimer;
  let camera;

  // hooks

  useFocusEffect(
    React.useCallback(() => {
      initScanTimer();
      return () => {
        clearScanTimer();
        resetSearchState();
      }
    }, [])
  );

  useEffect(() => {
    if (errorBulletin || errorTicket) {
      setErrorMessage(t('ticket.ref_not_found'));
    }
  }, [errorBulletin, errorTicket])


  // Functions

  const goManualScan = () => {
    navigation.navigate('ScanManual');
  }

  const onBarCodeRead = ({ barcodes }) => {
    if (!barcodes?.length) return; //no barcode detected

    const code = barcodes[0].data;
    console.log("onBarCodeRead, entry = " + code);
    setScanEntry(code);

    if (isEbulletinFormat(code)) {
      dispatch(EBulletionActions.searchBulletin(code));
    } else {
      dispatch(TicketActions.searchTicket(code));
    }
  }

  const initScanTimer = () => {
    setErrorMessage('');
    clearScanTimer();
    setScanTimer(setTimeout(() => setErrorMessage(t('scan.dialog_error_qrcode')), SCAN_TIMEOUT));
  }
  const clearScanTimer = () => {
    scanTimerRef.current && clearTimeout(scanTimerRef.current);
  }

  const retryScan = () => {
    resetSearchState();
    initScanTimer();
    camera?.refreshAuthorizationStatus();
  }

  const resetSearchState = () => {
    dispatch(EBulletionActions.reset_search_state());
    dispatch(TicketActions.reset_search_state());
  }

  const onScanStatusChange = ({ cameraStatus }) => {
    if (cameraStatus == 'NOT_AUTHORIZED') {
      setErrorMessage(t('scan.scan_not_available'));      
    }
  }

  const PendingView = ({ status }) => (
    <View style={style.pending_view}>
      <Text style={style.pending_text}>
        {
          status == 'NOT_AUTHORIZED' ? t('scan.scan_not_available') : t('loading')
        }
      </Text>
    </View>
  );


  return (
    <View style={style.container}>

      {/* //TODO:EXPO */}
      {/* {isFetching == false &&
        <RNCamera
          ref={ref => camera = ref}
          style={style.camera_preview}
          type={RNCamera.Constants.Type.back}
          flashMode={RNCamera.Constants.FlashMode.auto}
          captureAudio={false}
          onGoogleVisionBarcodesDetected={onBarCodeRead}
          onStatusChange={onScanStatusChange}
        >
          {({ status }) => {
            if (status !== 'READY') return <PendingView status={status} />;
          }}
        </RNCamera>
      } */}

      {
        isFetching &&
        <View style={style.search_in_progress}>
          <ActivityIndicator size="large" />
          <Text>{t('search_in_progress')}</Text>
        </View>
      }

      <Modal animationType="slide" transparent={true} visible={errorMessage?.length > 0}>
        <ScanDialog
          message={errorMessage}
          subMessage={scanEntry ? t('ref') + scanEntry : undefined}
          mainAction={retryScan}
          mainActionTitle={t('retry')}
          alternativeAction={goManualScan}
          alternativeActionTitle={t('scan.dialog_action_manual_entry')}
          onClose={retryScan}
        />
      </Modal>

    </View>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  camera_preview: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pending_view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pending_text: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 5,
    fontWeight: 'bold',
  },
  search_in_progress: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: -20,
  },
})

//======================================
export default ScanPreviewPage;