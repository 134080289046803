import { ACTIONS } from '../actions/ebulletin.actions';

// initial state of this reducer

const initialState = {
  error: false,
  isFetching: false,
  //
  currentBulletin: {},
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function ebulletinReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.BULLETIN_ERROR: {
      console.warn("Bulletin action error : " + JSON.stringify(action.payload));
      return { ...state, error: action.payload, isFetching: false }
    }
    case ACTIONS.BULLETIN_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.BULLETIN_FETCHING_DONE: {
      return { ...state, isFetching: false }
    }
    case ACTIONS.BULLETIN_RESET_SEARCH_STATE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    case ACTIONS.BULLETIN_CURRENT_BULLETIN:
      return { ...state, currentBulletin: action.payload }
    default:
      return state
  }
}