import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ScanPage from './Scan.page';
import ScanManualPage from './ScanManual.page';
import { useTranslation } from 'react-i18next';
import { styleStackScreenOptions } from '../../common/theme/theme';
import ScanPreviewPage from './ScanPreview.page';
import screens from '../../common/constants/screen.constants';
import ScanHelpPage from './ScanHelp.page';

const Stack = createNativeStackNavigator();


const ScanNavigation = () => {

  const { t } = useTranslation("translations");

  return (
    <Stack.Navigator
      initialRouteName="ScanHome"
      screenOptions={{...styleStackScreenOptions}}
    >
      <Stack.Screen name={screens.ScanHome} component={ScanPage} options={{title: t('tab scan')}} />
      <Stack.Screen name={screens.ScanManual} component={ScanManualPage} options={{title: t('Manual entry')}} />
      <Stack.Screen name={screens.ScanPreview} component={ScanPreviewPage} options={{title: t('tab scan')}} />

      <Stack.Screen name={screens.ScanHelp} component={ScanHelpPage} options={{title: t('scan.find_code'), presentation: 'modal'}} />
    </Stack.Navigator>
  )
}

export default ScanNavigation;