import * as React from "react"
import Svg, { Defs, Path, Circle } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SVGLoteries = (props) => (
  <Svg
    id="Calque_1"
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    {...props}
  >
    <Defs></Defs>
    <Path
      className="cls-1"
      d="M31.53 2.74a5.4 5.4 0 0 1 5.39 5.39v24a5.4 5.4 0 0 1-5.39 5.39H8.47a5.4 5.4 0 0 1-5.39-5.42v-24a5.4 5.4 0 0 1 5.39-5.36h23.06m0-1.18H8.47A6.57 6.57 0 0 0 1.9 8.13v24a6.57 6.57 0 0 0 6.57 6.57h23.06a6.57 6.57 0 0 0 6.57-6.6v-24a6.57 6.57 0 0 0-6.57-6.57Z"
    />
    <Circle className="cls-1" cx={10.02} cy={17.61} r={2.47} />
    <Circle className="cls-1" cx={16.67} cy={17.61} r={2.47} />
    <Circle className="cls-1" cx={23.33} cy={17.61} r={2.47} />
    <Circle className="cls-1" cx={29.98} cy={17.61} r={2.47} />
    <Circle className="cls-1" cx={10.02} cy={24.38} r={2.47} />
    <Circle className="cls-1" cx={16.67} cy={24.38} r={2.47} />
    <Circle className="cls-1" cx={23.33} cy={24.38} r={2.47} />
    <Circle className="cls-1" cx={29.98} cy={24.38} r={2.47} />
    <Circle className="cls-1" cx={10.02} cy={31.15} r={2.47} />
    <Circle className="cls-1" cx={16.67} cy={31.15} r={2.47} />
    <Circle className="cls-1" cx={23.33} cy={31.15} r={2.47} />
    <Circle className="cls-1" cx={29.98} cy={31.15} r={2.47} />
    <Path
      className="cls-1"
      d="M34.77 12.41H5.72a.3.3 0 0 1 0-.59h29.05a.3.3 0 0 1 0 .59ZM20 4.76l.81 1.63 1.8.27-1.3 1.27.31 1.8L20 8.88l-1.62.85.31-1.8-1.3-1.27 1.8-.27.81-1.63z"
    />
  </Svg>
)

export default SVGLoteries;

