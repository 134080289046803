import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const style = StyleSheet.create({
  container: {
      padding: 20,
  },
})

const MyBets = ({ navigation }) => {

  return (
    <View style={style.container}>
      <Text>MyBets Tab</Text>
    </View>
  )

}

export default MyBets; 