import { View, Text, StyleSheet } from "react-native";
import { useNavigation, useTheme } from '@react-navigation/native';
import React from "react";

export default function Basket({route}) {

  const theme = useTheme();
  const style = makeStyles(theme);

  return (
    <View style={style.body}>
      <Text style={style.title}>BASKET</Text>
    </View>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingVertical: 18,
    paddingHorizontal: 15,
    flex: 1,
    marginHorizontal: 25
  },
  title: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    color: '#404040',
  }
})
