import { useTheme } from '@react-navigation/native';
import React from 'react';
import { Pressable, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const ScanDialog = ({ message, subMessage, mainAction, mainActionTitle, alternativeAction, alternativeActionTitle, onClose }) => {

  const style = makeStyles(useTheme())

  return (
    <View style={style.centeredView}>
      <View style={style.modalView}>

        <TouchableOpacity style={style.closeButton} onPress={onClose}>
          <Icon name={'close'} style={style.closeIcon} />
        </TouchableOpacity>

        <Text style={style.message}>{message}</Text>
        {subMessage && <Text style={style.subMessage}>{subMessage}</Text>}

        <Pressable style={[style.button, style.button_main]} onPress={mainAction}>
          <Text style={style.button_main_text}>{mainActionTitle}</Text>
        </Pressable>

        <Pressable style={[style.button, style.button_alternative]} onPress={alternativeAction}>
          <Text style={style.button_alternative_text}>{alternativeActionTitle}</Text>
        </Pressable>

      </View>
    </View>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#DDDDDD99', //backdrop opacity
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 6,
    elevation: 4,
    padding: 20,
    marginTop: -70,
    alignItems: "center",
    width: '95%',
    maxWidth: 400,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 10,
    margin: 10,
    borderRadius: 4,
    width: '90%',
  },
  button_main: {
    backgroundColor: theme.colors.primary,
  },
  button_alternative: {
    backgroundColor: "#fff",
    borderWidth: 1,
  },
  button_main_text: {
    fontSize: 16,
    paddingLeft: 40,
    fontWeight: '600',
    color: 'white',
  },
  button_alternative_text: {
    fontSize: 16,
    paddingLeft: 40,
    fontWeight: '600',
    color: '#000',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
  },
  closeIcon: {
    fontSize: 26,
    color: '#000',
  },
  message: {
    textAlign: 'center',
    fontWeight: '600',
    color: "#444",
    fontSize: 17,
    paddingTop: 35,
    paddingBottom: 20
  },
  subMessage: {
    textAlign: 'center',
    color: "#888",
    marginTop: -15,
    paddingBottom: 20
  },
})

//======================================
export default ScanDialog;