import i18n from "../../common/i18n/i18n";
import { APP_INIT } from "../actions/login.actions";

const initialState = {
    token: null,
    isKeycloackLogged: false,
    error: '',
}

export default function loginReducer(state = initialState, action) {
    switch (action.type){
        case APP_INIT.APP_INIT_PENDING:
            return { ...state, isKeycloackLogged: false };
        case APP_INIT.APP_INIT_TOKEN_EXIST:
            return { ...state, isKeycloackLogged: true, token: action.payload.access_token };
        case APP_INIT.APP_INIT_DONE:
            return { ...state, isKeycloackLogged: true, token: action.payload.data };
        case APP_INIT.APP_INIT_FAILED:
            return { ...state, isKeycloackLogged: false, error: action.payload};
        default:
            return state;
    }
}