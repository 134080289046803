import screens from "../../common/constants/screen.constants";
import { navigate } from "../services/navigation.service";
import * as service from "../services/ticket.service";

// Definition of the actions name

export const ACTIONS = {
  TICKET_ERROR: 'TICKET_ERROR',
  TICKET_FETCHING: 'TICKET_FETCHING',
  TICKET_FETCHING_DONE: 'TICKET_FETCHING_DONE',
  TICKET_RESET_SEARCH_STATE: 'TICKET_RESET_SEARCH_STATE',
  //
  TICKET_CURRENT_TICKET: 'TICKET_CURRENT_TICKET',
}

export const set_state_error = (err) => {
  return { type: ACTIONS.TICKET_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.TICKET_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.TICKET_FETCHING_DONE };
}
export const reset_search_state = () => {
  return { type: ACTIONS.TICKET_RESET_SEARCH_STATE };
}

export const set_current_ticket = (ticket) => {
  return { type: ACTIONS.TICKET_CURRENT_TICKET, payload: ticket };
}

//

export const searchTicket = (reference) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getTicket(reference);
      if (r.data?.ticket) {
        dispatch(set_current_ticket(r.data?.ticket))
        navigate(screens.Ticket, { reference }); //TODO:
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
