import React, { useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { View, StyleSheet, TextInput } from 'react-native';

import { SVGSearch } from '../../assets/icons';

const SearchInput = props => {

    const { t, placeHolder } = props;
    const theme = useTheme();
    const style = makeStyles(theme);

    const [gameSearch, setGameSearch] = useState('');

    return (
        <View style={style.searchSection}>
            <SVGSearch fill="#2655F9" style={{width: 24,height: 24}} />
            <TextInput
                style={style.input}
                onChangeText={setGameSearch}
                value={gameSearch}
                placeholder={t(placeHolder)}
                placeholderTextColor={theme.colors.primary}
            />
        </View>
    );
};

export default SearchInput;

const makeStyles = (theme) => StyleSheet.create({
  searchSection: {
      height: 35,
      width: '95%',
      margin: 12,
      borderWidth: 1,
      padding: 10,
      borderRadius: 5,
      backgroundColor: '#ffffff',
      color: theme.colors.primary,
      fontSize: 10,
      borderColor: theme.colors.primary,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
},
  button_svg: {
      width: 24,
      height: 24,
  },
  input: {
      flex: 1,
      padding: 10,
      color: theme.colors.primary,
      height: 35,
      width: '95%',
      fontSize: 12,
      fontFamily: 'Raleway-Regular',
  },
});