import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, Text, StyleSheet, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as betCreatorAction from '../../store/actions/betCreator.actions';

import NextDrawCountdown from '../../components/games/NextDrawCountdown.comp';
import { GameRulesLink } from '../../components/betCreation/GameRulesLink.comp';
import screens from '../../common/constants/screen.constants';

import BetWithoutAdditionnal from '../../components/betCreation/BetWithoutAdditionnal';
import BetWithAdditionnal from '../../components/betCreation/BetWithAdditionnal';
import StakePicker from '../../components/betCreation/StakePicker';
import ConsecutiveDrawPicker from '../../components/betCreation/ConsecutiveDrawPicker';

import * as Raleway from '@expo-google-fonts/raleway';

import {  
  SVGFlash,
  SVGResult,
  SVGStats,
  SVGFavorisFull,
} from '../../assets/icons';

const GridPicker = () => {

  const navigation = useNavigation();
  const { t } = useTranslation("translations");
  const theme = useTheme();
  const styles = makeStyles(theme);
  const dispatch = useDispatch();

  const currentGame = useSelector(state => state.games.currentGame);
  const selectedBetType = useSelector(state => state.betCreator.selectedBetType);

  const currency = 'FCFA';

  const optionsList = [
    {
        icon: <SVGFlash fill='#30C408' style={{width: 28, height: 28, marginBottom: 5}} />,
        title: "Flash"
    },
    {
        icon: <SVGFavorisFull fill={theme.colors.primary} style={{width: 28, height: 28, marginBottom: 5}} />,
        title: "Favourites"
    },
    {
        icon: <SVGStats fill='#FFA62A' style={{width: 28, height: 28, marginBottom: 5}} />,
        title: "Stats"
    },
    {
        icon: <SVGResult fill={theme.colors.primary} style={{width: 28, height: 28, marginBottom: 5}} />,
        title: "Résultats"
    }
  ];

  const [playerInfos, setPlayerInfos] = useState(
    selectedBetType.listOfGridParameters.reduce((acc, grid) => {
      return { 
        ...acc,
        [grid.gridName]: 
          { 
            ...grid,
            ...selectedBetType.listOfGrids.find(elem => elem.gridName === grid.gridName),
            playerNumber: [],
            playerNumberAdditional: []
          }
        }
    }, {})
  );

  const [isAdditional, setIsAdditional] = useState(
    selectedBetType.listOfGridParameters.reduce((acc, grid) => {
      return acc || grid.maxAdditional > 0;
    }, false)
  );

  const [nbDrawSelected, setNbDrawSelected] = useState(1);
  const [selectedPrice, setSelectedPrice] = useState('');

  //====================================== Hooks

  // useEffect(() => {
  //   console.log(playerInfos)
  // }, [playerInfos]);

  //====================================== Functions

  function manipulatePlayerInfos(number, currentGrid) {
    const currentNumberPlayer = playerInfos[currentGrid];
    if(currentNumberPlayer) {
      const { playerNumber, requiredNumber, playerNumberAdditional, maxAdditional } = currentNumberPlayer;
      let temporaryNumbs = playerNumber;
      let tempAddNumbs = playerNumberAdditional;

      // playerNumber Part
      if (playerNumber.length < requiredNumber && !(playerNumber.includes(number))) {
        temporaryNumbs.push(number);
      }
      else if ((playerNumber.length === requiredNumber && playerNumber.includes(number)) ||
      (playerNumber.length < requiredNumber && playerNumber.includes(number))) {
        temporaryNumbs = playerNumber.filter(item => item !== number);
      }
      // additionnal playerNumber Part
      else if (maxAdditional > 0 && playerNumber.length === requiredNumber && playerNumberAdditional.length < maxAdditional && !(playerNumberAdditional.includes(number))) {
        tempAddNumbs.push(number);
      }
      else if (maxAdditional > 0 && playerNumber.length === requiredNumber && playerNumberAdditional.length === maxAdditional && playerNumberAdditional.includes(number)) {
        tempAddNumbs = playerNumberAdditional.filter(item => item !== number);
      }
      
      // Set all the infos of the player numbers
      setPlayerInfos({...playerInfos, [currentGrid]: {...currentNumberPlayer, playerNumber: temporaryNumbs, playerNumberAdditional: tempAddNumbs}});

    } else {
      return;
    }
  }

  function erasePlayerInfos(currentGrid) {
    const currentNumberPlayer = playerInfos[currentGrid]
    if(currentNumberPlayer) {
      const { playerNumber } = currentNumberPlayer;
      if(playerNumber.length === 0){
        return;
      } else {
        setPlayerInfos({...playerInfos, [currentGrid]: {...currentNumberPlayer, playerNumber: [], playerNumberAdditional: []}});
      }
    }
  }

  function validateGrid() {
    navigation.navigate(screens.basket, {playerInfos, nbDrawSelected, selectedPrice, totalAmount: nbDrawSelected * selectedPrice});
  }

  function isEverythingSelected(nA) {
    return Object.values(nA).reduce((acc, grid) => {
      return acc && grid.playerNumber.length === grid.requiredNumber;
    }, true) && selectedPrice > 0;
  }

  function optionsListFunctions(type) {
    switch (type) {
      case 'Flash':
        flashNumbers();
        break;
      default:
        break;
    }
  }

  function flashNumbers() {
    selectedBetType.listOfGridParameters.forEach((elem) => {

      let n = 0;
      let randomNumbs = [];

      const currentGrid = playerInfos[elem.gridName];
      const { listOfLottoNumbers, requiredNumber, maxAdditional } = currentGrid;

      // random player numbers
      while (n < requiredNumber) {
        randomNumbs.push(listOfLottoNumbers[Math.floor(Math.random() * listOfLottoNumbers.length)])
        n++;
      }

      setPlayerInfos(player => (
          {
            ...player,
            [elem.gridName]: {
              ...currentGrid,
              playerNumber: randomNumbs,
              playerNumberAdditional: []
            }
          }
        )
      );
    })
  }

  //====================================== Render

  return (
    <View style={styles.container}>
      <NextDrawCountdown />
      <ScrollView>
        <View style={styles.body}>

          {/* List */}
          <View style={styles.cardContainer}>
          {optionsList.map((elem, index) => {
              return (
                <Pressable key={index} style={styles.cardMenu} onPress={() => optionsListFunctions(elem.title)}>
                    {elem.icon}
                  <Text style={styles.title}>{elem.title}</Text>
                </Pressable>
                )
            })}
          </View>

          <GameRulesLink game={currentGame} />

          {/* Dynamic part */}
          {isAdditional ?
            <BetWithAdditionnal
              t={t}
              selectedBetType={selectedBetType}
              styles={styles} playerInfos={playerInfos}
              manipulatePlayerInfos={manipulatePlayerInfos}
              erasePlayerInfos={erasePlayerInfos}
              currency={currency}
            /> :
            <BetWithoutAdditionnal
              t={t}
              selectedBetType={selectedBetType}
              styles={styles} playerInfos={playerInfos}
              manipulatePlayerInfos={manipulatePlayerInfos}
              erasePlayerInfos={erasePlayerInfos}
              currency={currency}
            />
          }

          <StakePicker 
            t={t}
            selectedBetType={selectedBetType}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
            currency={currency}
          />

          <ConsecutiveDrawPicker
            t={t}
            selectedBetType={selectedBetType}
            nbDrawSelected={nbDrawSelected}
            setNbDrawSelected={setNbDrawSelected}
          />

          <View style={styles.recapContainer}> 
            <View style={styles.recapGrid}>
              <Text style={styles.recapTitle}>
                {t('Draws number')} : {nbDrawSelected}
              </Text>
            </View>
            <View style={styles.recapGrid}>
              <Text style={styles.recapTitle}>
                {t('Total bet')} : {nbDrawSelected * selectedPrice} {currency}
              </Text>   
            </View>
          </View>

          <Pressable  
            onPress={() => {
              validateGrid();
            }}
            style={!isEverythingSelected(playerInfos) ? styles.disabledValidateButton : styles.validateButton}
            disabled={!isEverythingSelected(playerInfos)}
          >
            <Text style={styles.textButton}>
              {t('Validate')}
            </Text>
          </Pressable >

        </View>
      </ScrollView>
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingVertical: 18,
    paddingHorizontal: 15,
    flex: 1,
    marginHorizontal: 25,
  },
  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20
  },
  cardMenu: {
    height: 85,
    width: 70,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginHorizontal: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
    color: theme.colors.greyText,
  },
  title: {
    color: '#8F8E95',
    fontFamily: 'Raleway-Regular',
    fontSize: 11
  },
  grid: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  gridTitle: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
  restTitle: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 13,
  },
  gridPicker: {
    flexDirection: 'row',
    flexWrap: "wrap",
    justifyContent: 'center',
    width: '90%',
    marginTop: 15
  },
  pickNumber: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  pickNumberPressed: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: theme.colors.primary,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  oddPickNumberPressed: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: '#FFA62A',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  AdditionalPickNumberPressed: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: '#BD54EB',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  number: {
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    color: theme.colors.greyText
  },
  numberPressed: {
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    color: '#ffffff'
  },
  validateButton: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    marginBottom: 10
  },
  disabledValidateButton: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    opacity: 0.6,
    marginBottom: 10
  },
  textButton: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
  eraseButton: {
    backgroundColor: 'transparent',
    width: 100,
    height: 35,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 28,
    borderWidth: 1,
    borderColor: theme.colors.primary
  },
  textEraseButton: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  recapTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
    marginTop: 15,
    color: theme.colors.greyText,
  },
})

//======================================
export default GridPicker;