import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'

import betCreator from './reducers/betCreator.reducer';
import ebulletin from './reducers/ebulletin.reducer';
import games from './reducers/games.reducer';
import hello from './reducers/hello.reducer';
import login from './reducers/login.reducer';
import ticket from './reducers/ticket.reducer';

const rootReducer = {
  betCreator,
  ebulletin,
  games,
  hello,
  login,
  ticket,
}

export default createStore(combineReducers(rootReducer), applyMiddleware(thunk));