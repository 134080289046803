import React from 'react';
import { View, Text, StyleSheet, Button } from 'react-native';

const style = StyleSheet.create({
  container: {
      padding: 20,
  },
})

const MySpace = ({ navigation, route }) => {

  const onPressNavigateTab = () => {
    navigation.navigate('Games');
  }
  const onPressNavigateFavorites = () => {
    navigation.navigate('UserFavorites', {id: 987});
  }

  return (
    <View style={style.container}>
      <Button title="Navigate to 'Games'" onPress={onPressNavigateTab} />
      <Text>User Tab ID={route?.params?.id}</Text>
      <Button title="Navigate to sub screen UserFavorites" onPress={onPressNavigateFavorites} />
    </View>
  )

}

export default MySpace; 