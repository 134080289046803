import { ACTIONS } from '../actions/ticket.actions';

// initial state of this reducer

const initialState = {
  error: false,
  isFetching: false,
  //
  currentTicket: {},
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function ticketReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.TICKET_ERROR: {
      return { ...state, error: action.payload, isFetching: false }
    }
    case ACTIONS.TICKET_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.TICKET_FETCHING_DONE: {
      return { ...state, isFetching: false }
    }
    case ACTIONS.TICKET_RESET_SEARCH_STATE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    case ACTIONS.TICKET_CURRENT_TICKET:
      return { ...state, currentTicket: action.payload }
    default:
      return state
  }
}