import * as service from "../services/ebulletin.service";

// Definition of the actions name

export const ACTIONS = {
  BULLETIN_ERROR: 'BULLETIN_ERROR',
  BULLETIN_FETCHING: 'BULLETIN_FETCHING',
  BULLETIN_FETCHING_DONE: 'BULLETIN_FETCHING_DONE',
  BULLETIN_RESET_SEARCH_STATE: 'BULLETIN_RESET_SEARCH_STATE',
  //
  BULLETIN_CURRENT_BULLETIN: 'BULLETIN_CURRENT_BULLETIN',
}

// Export action

export const set_state_error = (err) => {
  return { type: ACTIONS.BULLETIN_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.BULLETIN_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.BULLETIN_FETCHING_DONE };
}
export const reset_search_state = () => {
  return { type: ACTIONS.BULLETIN_RESET_SEARCH_STATE };
}

export const set_current_bulletin = (bulletin) => {
  return { type: ACTIONS.BULLETIN_CURRENT_BULLETIN, payload: bulletin };
}

//

export const searchBulletin = (code) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getBulletin(code);
      if (r.data?.bulletin) {
        dispatch(set_current_bulletin(r.data?.bulletin))
        dispatch(set_state_fetching_done());
        // alert(JSON.stringify(r.data?.bulletin, null, 2));
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
