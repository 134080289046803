export const translationEN = {
    /*eslint-disable */

    /** -------------------------------------------- GENERAL DICTIONARY  ----------------------------------------*/
    "back": "Back",
    "games": "Games",
    "go_back_home": "Back to homepage",
    "loading": "Loading...",
    "loading_data": "Loading data...",
    "lotteries": "Lotteries",
    "no": "No",
    "ref": "Ref.",
    "results": "Results",
    "retry": "Retry",
    "search_in_progress": "Search in progress...",
    "today": "Today",
    "today_at": "Today at",
    "validate": "Validate",
    "yes": "Yes",
    "Next draws": "Next Draws",
    /** =========================================================================================================*/

    /** ------------------------------------------------ BET CREATION ----------------------------------------------*/
    "end_of_bets": "End of bets",
    "game_rules": "Game rules",
    "minute": "minute",
    "minutes": "minutes",
    "next_draw": "Next draw",
    "next_draw_less": "Next draw in less than",
    "no_draw_available": "No draws are available at the moment. Please try again later.",
    "select_bet_type": "Choose a game type",
    "select_game_sessions": "Choose one or more game sessions",
    "select_game_session": "Choose a game session",
    "Validate": "Validate",
    "Delete": "Delete",
    "Remaining": "Remaining",
    "Stake per bet": "Stake per bet",
    "Number of draws": "Number of draws",
    "Summary": "Summary",
    "Total bet": "Total bet",
    'additional numbers': 'additional numbers',
    "Custom amount": "Custom amount",
    "Select amount": "Select your amount",
    "Select between": "Select an amount between",
    "And": "and",

    /** ------------------------------------------------ ERRORS ----------------------------------------------*/
    "error.generic": "An error has occured.",
    "Something went wrong": "Something went wrong",
    "The app takes too long to start": "The app takes too long to start",

    /** ------------------------------------------------ E-BULLETIN ----------------------------------------------*/
    "ebulletin.tag": "E-Bulletin",

    /** ------------------------------------------------ SCAN ----------------------------------------------*/
    "Manual entry": "Manual entry",
    "scan.button_qrcode": "Scan a QR Code",
    "scan.button_code": "Enter a code",
    "scan.dialog_action_manual_entry": "Enter the code manually",
    "scan.dialog_action_qrcode": "Scan a QR Code",
    "scan.dialog_error_qrcode": "We could not read your QR code.",
    "scan.find_code": "Where can i find my code ?",
    "scan.help_bulletin_1": "Open the tab ‟My Bets”",
    "scan.help_bulletin_2": "Select ‟E-Bulletins”",
    "scan.help_bulletin_3": "Open the bulletin of your choice by clicking on ‟View”",
    "scan.help_bulletin_4": "The code is right below the QR code",
    "scan.help_bulletin_title": "Enter an e-bulletin code to load the same bet to your phone, or share your e-bulletin with a friend.",
    "scan.help_ticket_title": "Enter the code present on your game ticket, right above your QR code.",
    "scan.manual_entry_label": "Enter your code",
    "scan.manual_entry_label_2": "(ticket or e-bulletin)",
    "scan.manual_placeholder": "Enter the code",
    "scan.scan_not_available": "The camera is not available. Please check permissions on your smartphone.",
    "scan.scan_ticket_or_bulletin": "Scan a ticket or a e-bulletin",
    "Scan unavailable": "Only available on Android or IOS app.",

    /** ------------------------------------------------ TABS ----------------------------------------------*/
    "my bets": "My bets",
    "my space": "My space",
    "tab scan": "Scan",

    /** ------------------------------------------------ SCAN ----------------------------------------------*/
    "Manual entry": "Manual entry",
    "scan.button_qrcode": "Scan a QR Code",
    "scan.button_code": "Enter a code",
    "scan.find_code": "Where can i find my code ?",
    "scan.manual_entry_label": "Enter your code",
    "scan.manual_entry_label_2": "(ticket or e-bulletin)",
    "scan.manual_placeholder": "Enter the code",
    "scan.scan_ticket_or_bulletin": "Scan a ticket or a e-bulletin",

    /** ------------------------------------------------ GAMES ----------------------------------------------*/
    "Find a game": "Find a game",
    "Discover": "Discover",
    "See all": "See all",
    /** ------------------------------------------------ TICKET ----------------------------------------------*/
    "ticket.ref_not_found": "Nous n'avons pas trouvé votre ticket.",
    "Ticket": "Ticket",
    "Status": "Status",
    "Total bet": "Total bet",
    "Draws made": "Draws made",
    "Draws pending": "Draws pending",
    "Total gains": "total gains",

    "Game type": "Game type",
    "Bet": "Bet",
    "Gains": "Gains",
    "Draw": "Draw",
    "Selection": "Selection",

    "Expired": "Expired",
    "Lost": "Lost",
    "Won": "Won",
    "Pending": "Pending",
    "Finished": "Finished",
    "Canceled": "Canceled",
    "Price": "Price",

    "Draw results": "Draw results",
    "Your selection": "Your selection",
    "Winning combinations": "Winning combinations",
    "Rank": "Rank",
}