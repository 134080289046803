export const translationPT = {
    /*eslint-disable */

    /** -------------------------------------------- GENERAL DICTIONARY  ----------------------------------------*/
    "back": "Voltar",
    "games": "Jogos",
    "go_back_home": "Voltar à página inicial",
    "loading": "Carregando...",
    "lotteries": "Loterias",
    "no": "Não",
    "ref": "Ref.",
    "results": "Resultados",
    "retry": "Tentar de novo",
    "search_in_progress": "Procurando, por favor aguarde...",
    "today": "Hoje",
    "today_at": "Hoje as",
    "validate": "Validar",
    "yes": "Sim",
    "Next draws": "Próximos sorteios",
    /** =========================================================================================================*/

    /** ------------------------------------------------ BET CREATION ----------------------------------------------*/
    "end_of_bets": "Fim das apostas",
    "game_rules": "Regras do jogo",
    "minute": "minuto",
    "minutes": "minutos",
    "next_draw": "Próximo sorteio",
    "next_draw_less": "Próximo sorteio em menos de 10 minutos",
    "no_draw_available": "Não há sorteio disponíveis no momento. Tente mais tarde.",
    "select_bet_type": "Escolha um tipo de jogo",
    "select_game_sessions": "escolha uma ou mais sessões de jogo",
    "select_game_session": "escolha uma sessão de jogo",
    "Validate": "Validar",
    "Delete": "Excluir",
    "Remaining": "Remanescente",
    "Stake per bet": "Aposta por aposta",
    "Number of draws": "Número de sorteios",
    "Summary": "Resumo",
    "Total bet": "Aposta total",
    "additional numbers": "números adicionais",
    "Custom amount": "Valor personalizado",
    "Select amount": "Selecione seu valor",
    "Select between": "Selecione um valor entre",
    "And": "e",

    /** ------------------------------------------------ ERRORS ----------------------------------------------*/
    "error.generic": "Ocorreu um erro.",
    "Something went wrong": "Algo deu errado",
    "The app takes too long to start": "O aplicativo demora muito para iniciar",

    /** ------------------------------------------------ ERRORS ----------------------------------------------*/
    "error.generic": "Ocorreu um erro.",

    /** ------------------------------------------------ E-BULLETIN ----------------------------------------------*/
    "ebulletin.tag": "E-Boletim",

    /** ------------------------------------------------ SCAN ----------------------------------------------*/
    "Manual entry": "Entrada manual",
    "scan.button_qrcode": "Digitalize um código QR",
    "scan.button_code": "Insira um código",
    "scan.dialog_action_manual_entry": "Insira seu código manualmente",
    "scan.dialog_action_qrcode": "Digitalize um código QR",
    "scan.dialog_error_qrcode": "Não foi possível ler seu código QR.",
    "scan.find_code": "Onde posso encontrar meu código ?",
    "scan.help_bulletin_1": "Abra ‟Minhas Apostas”",
    "scan.help_bulletin_2": "Selecionar ‟E-Boletins”",
    "scan.help_bulletin_3": "Abra o boletim de sua escolha clicando em ‟Visualizar”",
    "scan.help_bulletin_4": "O código está logo abaixo do código QR",
    "scan.help_bulletin_title": "Insira um código de e-boletim para carregar a mesma aposta em seu telefone ou compartilhe seu e-boletim com um amigo.",
    "scan.help_ticket_title": "Digite o código presente no seu tíquete de jogo, logo acima do código QR.",
    "scan.manual_entry_label": "Insira seu código",
    "scan.manual_entry_label_2": "(bilhete ou e-boletim)",
    "scan.manual_placeholder": "Coloque o código",
    "scan.scan_not_available": "A câmera não está disponível. Por favor, verifique as permissões no seu smartphone.",
    "scan.scan_ticket_or_bulletin": "Digitalize um tíquete ou um boletim eletrônico",
    

    /** ------------------------------------------------ TABS ----------------------------------------------*/
    "my bets": "Minhas apostas",
    "my space": "Meu espaço",
    "tab scan": "Scan",

    /** ------------------------------------------------ SCAN ----------------------------------------------*/
    "Manual entry": "Entrada manual",
    "scan.button_qrcode": "Digitalize um código QR",
    "scan.button_code": "Insira um código",
    "scan.find_code": "Onde posso encontrar meu código ?",
    "scan.manual_entry_label": "Insira seu código",
    "scan.manual_entry_label_2": "(bilhete ou e-boletim)",
    "scan.manual_placeholder": "Coloque o código",
    "scan.scan_ticket_or_bulletin": "Digitalize um tíquete ou um boletim eletrônico",
    "Scan unavailable": "Disponível apenas no aplicativo Android ou IOS.",

    /** ------------------------------------------------ GAMES ----------------------------------------------*/
    "Find a game": "Encontre um jogo",
    "Discover": "Descobrir",
    "See all": "Ver tudo",
    /** ------------------------------------------------ TICKET ----------------------------------------------*/
    "ticket.ref_not_found": "Nous n'avons pas trouvé votre ticket.",
    "Ticket": "Bilhete",
    "Status": "Status",
    "Total bet": "Aposta total",
    "Draws made": "Sorteios feitos",
    "Draws pending": "Sorteios restantes",
    "Total gains": "Ganhos cumulativos",

    "Game type": "Tipo de jogo",
    "Bet": "Aposta",
    "Gains": "Ganhos",
    "Draw": "Desenhar",
    "Selection": "Seleção",

    "Expired": "Expirado",
    "Lost": "Perdido",
    "Won": "Ganho",
    "Pending": "Pendente",
    "Finished": "Finalizado",
    "Canceled": "Cancelado",
    "Price": "Preço",
    
    "Draw results": "Resultados do sorteio",
    "Your selection": "Sua seleção",
    "Winning combinations": "Combinações vencedoras",
    "Rank": "Posição",
}