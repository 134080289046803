import { apiWebPlayerConfig } from '../../../app.json';
import { fillWebPlayerSecurityParams, wp_axios } from './api.webplayer.service';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;
const API_URL_v2a = API_URL.replace("/v2/", "/v2-a/");
const MOCK_URL = apiWebPlayerConfig.MOCK_URL;

// GAMES

export const getGames = async () => {
  let params = { };
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${API_URL}/games`, { params }); //TODO: EXPO: l'appel api ne fonctionne pas, problème de token/nonce/...
  // return wp_axios.get(`${MOCK_URL}/games`, { params }); //TODO: EXPO: supprimer une fois le problème corrigé
}

// BET TYPES

export const getMutualBetTypeList = async (gameId) => {
  let params = {};
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/${gameId}/lottery/mutual/betTypes`, { params });
}

export const getInstantBetTypeList = async (gameId) => {
  let params = {};
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/${gameId}/lottery/instant/betTypes`, { params });
}

export const getInstantBetType = async (gameId, betTypeId) => {
  let params = {};
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/${gameId}/lottery/instant/betTypes/${betTypeId}`, { params });
}

// SESSIONS

export const getInstantSessionsFuture = async (gameId, max, before, after) => {
  let params = { max, before, after };
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/${gameId}/lottery/instant/draws/future`, { params });
}

export const getMutualSessionsFuture = async (gameId, max, before, after) => {
  let params = { max, before, after };
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/${gameId}/lottery/mutual/draws/future`, { params });
}

export const getAllInstantSessionsFuture = async (max, before, after) => {
  let params = { max, before, after };
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/all/lottery/instant/draws/future`, { params });
}

export const getAllMutualSessionsFuture = async (max, before, after) => {
  let params = { max, before, after };
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${MOCK_URL}/games/all/lottery/mutual/draws/future`, { params });
}


//=============================================================================
// HELPERS
//=============================================================================
