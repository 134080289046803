import { useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View, Text, StyleSheet, Platform, ToastAndroid } from 'react-native';
import { useDispatch } from 'react-redux';
import PopUpModal from '../../components/kpLibrary/popUpModal';

import { SVGKeyboard, SVGQrCode2 } from '../../assets/icons';


const ScanPage = ({ route }) => {

  const navigation = useNavigation();
  const { t } = useTranslation("translations");
  const style = makeStyles(useTheme())

  const [isWeb, setIsWeb] = useState(Platform.OS === "web");
  const [modalVisible, setModalVisible] = useState(false);

  const onQRScanClick = () => {
    isWeb ? setModalVisible(true) : navigation.navigate('ScanPreview');
  }

  const onManualScanClick = () => {
    navigation.navigate('ScanManual');
  }


  return (
    <View style={style.container}>

        <View>
          <Text style={style.text_scan_label}>
            {t('scan.scan_ticket_or_bulletin')}
          </Text>

          <Pressable
            style={[style.button, style.button_qrcode ]}
            onPress={onQRScanClick}
          >
            <SVGQrCode2
              fill="#fff"
              style={{
                marginRight: 16,
                position: 'absolute',
                left: 20,
                width: 24,
                height: 24,
                }}
            />
            <Text style={style.button_qrcode_text}>
              {t('scan.button_qrcode')}
            </Text>
          </Pressable>

          <Pressable
            style={[style.button, style.button_code]}
            onPress={onManualScanClick}
          >
            <SVGKeyboard
              fill="#000"
              style={{
                marginRight: 16,
                position: 'absolute',
                left: 20,
                width: 24,
                height: 24,
                }}
            />
            <Text style={style.button_code_text}>
              {t('scan.button_code')}
            </Text>
          </Pressable>
        </View>

        <PopUpModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          message={t('Scan unavailable')}
        />

    </View>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 50,
    flex: 1,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20,
    paddingHorizontal: 10,
    margin: 10,
    borderRadius: 4,
    elevation: 6,
  },
  button_qrcode: {
    backgroundColor: theme.colors.primary,
  },
  button_code: {
    backgroundColor: "#fff",
  },
  button_qrcode_text: {
    fontSize: 16,
    paddingLeft: 40,
    color: 'white',
    fontFamily: 'Raleway-Bold'
  },
  button_code_text: {
    fontSize: 16,
    paddingLeft: 40,
    color: '#000',
    fontFamily: 'Raleway-Bold'
  },
  text_scan_label: {
    textAlign: 'center',
    fontWeight: '600',
    color: "#444",
    fontSize: 17,
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: 'Raleway-Bold'
  },
})

//======================================
export default ScanPage;