import { getTokenObjectFromStorage, requestKeycloakToken, setAxiosCommonHeaders, storeTokenObjectToStorage } from "../services/login.service";
import * as apiWebPlayerService from "../services/api.webplayer.service";

export const APP_INIT = {
    APP_INIT_PENDING: 'APP_INIT_PENDING',
    APP_INIT_TOKEN_EXIST: 'APP_INIT_TOKEN_EXIST',
    APP_INIT_DONE: 'APP_INIT_DONE',
    APP_INIT_FAILED: 'APP_INIT_FAILED'
}

export const updateLogin = (action, pay) => {
    return pay ? { type: action, payload: pay } : { type: action };
}

export const initToken = async(dispatch) => {
    const tokenStorage = await getTokenObjectFromStorage();
    // console.warn("### tokenStorage", tokenStorage)
    if (!tokenStorage) {
        // getting keycloak token
        dispatch(onGetToken())
    } else {
        // token's already in storage
        //TODO: should always call the apis each time we open the app ?
        // dispatch(tokenAlreadyExist(tokenStorage));
        dispatch(onGetToken())
    }
}

export const stockTokenInStorage = async(tokenObj) => {
    if (tokenObj) {
        await storeTokenObjectToStorage(tokenObj);
    }
}

export const tokenAlreadyExist = (tokenObj) => {
    return async dispatch => {
        dispatch({ type: APP_INIT.APP_INIT_TOKEN_EXIST, payload: tokenObj });
    }
}

export const onGetToken = () => {
    return async dispatch => {
      try {
        dispatch(updateLogin(APP_INIT.APP_INIT_PENDING));
        const r = await requestKeycloakToken();
        if (r?.data?.access_token) {
            stockTokenInStorage(r.data);
            apiWebPlayerService.setWebPlayerAuthKey(r.data.access_token);
            await createWebPlayerSession();
            dispatch(updateLogin(APP_INIT.APP_INIT_DONE, r));
        }
      } catch(err) {
        dispatch({ type: APP_INIT.APP_INIT_FAILED, payload: err });
      }
    }
}

export const createWebPlayerSession = async() => {
    let session = await apiWebPlayerService.createWebPlayerSession();
    //TODO: now, we can make some updates related to the session data :
    //  - load some app settings like the defaultCurrency
    //  - check for app updates (applicationVersionStatus/applicationAdvisedUrl)
}