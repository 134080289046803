import { getAwesomeQuote } from "../services/hello.service";
// Definition of the actions name

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

export const UPDATE_QUOTE = 'UPDATE_QUOTE';

// Export action
export const increment = () => {
    return { type: INCREMENT };
}

export const updateQuote = (quote) => {
    return { type: UPDATE_QUOTE, payload: quote };
}

// Thanks to redux-thunk, dispatch can be async
// See https://github.com/reduxjs/redux-thunk

export const onGetQuote = () => {
    return async dispatch => {
        const r = await getAwesomeQuote();
        dispatch(updateQuote(r.data.content))
    }
}