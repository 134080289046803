import React, { useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { SVGBasket, SVGGameDice, SVGLotteryBall, SVGPerson, SVGQrCode } from '../../assets/icons';
import { useTheme } from '@react-navigation/native';

import images from '../../assets/images';
import MyBets from '../../screens/my-bets/MyBets';
import Results from '../../screens/results/results';

import { useTranslation } from 'react-i18next';
import { Image, View } from 'react-native';

import MySpaceNavigation from '../../screens/my-space/MySpace.navigation';
import ScanNavigation from '../../screens/scan/Scan.navigation';
import GamesNavigation from '../../screens/games/Games.navigation';

const Tab = createBottomTabNavigator();

const BottomNavigation = () => {

  const { t } = useTranslation("translations");
  const theme = useTheme();

  return (
    <Tab.Navigator
      screenOptions={({ route, navigation }) => ({
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: 'gray',
        tabBarLabelStyle: {
          fontFamily: 'Raleway-Bold',
          marginBottom: 0
        },
        tabBarShowLabel: true,
        tabBarStyle: {
          borderBottomWidth: 0,
          paddingBottom: 2,
          paddingTop: 4,
          borderColor: "transparent",
          justifyContent: 'center'
        },
        headerShown: false, // header managed by each tab sub-navigator
        headerStyle: {
          backgroundColor: theme.colors.primary,
          fontFamily: 'Raleway-Bold',
        },
        headerTintColor: "#fff",
        headerTitleAlign: 'center',
      })}
      initialRouteName="Games"
    >
      <Tab.Screen
        name="Games"
        component={GamesNavigation}
        options={{
          tabBarLabel: t('games'),          
          headerTitle: (props) => (
              <Image source={images.logo_operator} style={{ width: 200, height: 50 }}></Image>
          ),
          tabBarIcon: ({ color }) => <SVGGameDice fill={color} />
        }} />

      <Tab.Screen
        name="MySpace"
        component={MySpaceNavigation}
        options={{
          tabBarLabel: t('my space'),
          tabBarIcon: ({ color }) => <SVGPerson fill={color} />
        }} />

      <Tab.Screen
        name="MyBets"
        component={MyBets}
        options={{
          tabBarLabel: t('my bets'),
          tabBarIcon: ({ color }) => <SVGBasket fill={color} />
        }} />

      <Tab.Screen
        name="Results"
        component={Results}
        options={{
          tabBarLabel: t('results'),
          tabBarIcon: ({ color }) => <SVGLotteryBall style={{ width: 23, height: 23 }} fill={color} />
        }} />

      <Tab.Screen
        name="Scan"
        component={ScanNavigation}
        options={() => ({         
          tabBarLabel: t('tab scan'),
          tabBarIcon: ({ color }) => <SVGQrCode fill={color} />
        })} />

    </Tab.Navigator>
  );
};

export default BottomNavigation;
