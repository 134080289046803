import React, { useState } from "react";
import { useTheme } from "@react-navigation/native";
import { View, Text, StyleSheet, ImageBackground, Pressable, TouchableHighlight } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import {  
    SVGLaUne,
    SVGLoteries,
    SVGCasino,
    SVGFavoris,
    SVGTv,
} from '../../assets/icons';

const searchList = [
    {
        icon: <SVGLaUne />,
        title: "A la une"
    },
    {
        icon: <SVGLoteries />,
        title: "Loteries"
    },
    {
        icon: <SVGCasino />,
        title: "Casino"
    },
    {
        icon: <SVGFavoris />,
        title: "Favoris"
    },
    {
        icon: <SVGTv />,
        title: "Virtuels"
    }
];

function GameSearchFilter() {

    const [currentGameType, setCurrentGameType] = useState(null);
    const theme = useTheme();

    function svgBuild(Name, isPressed) {
       return <Name.icon.type fill={isPressed ? "#ffffff" : "#404040"} style={{width: 24, height: 24}} />;
    }

    return (
        <View style={style.container}>
            {searchList.map((elem, index) => {
              const [pressed, setPressed] = useState(false);
              return (
                <LinearGradient
                  key={index}
                  style={pressed ? style.cardPressed : style.card}
                  colors={pressed ? [theme.colors.primary, '#B11FC7'] : ['#ffffff', '#fffffe']}
                >
                    <Pressable
                      style={style.pressable}
                      onPress={() => {
                        setPressed(!pressed)
                      }}> 
                        <View style={style.view}>
                            {svgBuild(elem, pressed)}
                            <Text style={pressed ? style.titlePressed : style.title}>{elem.title}</Text>
                        </View>                     
                    </Pressable>
                </LinearGradient>
                )
            })}
        </View>
      );
}

const style = StyleSheet.create({
  container: {
      height: 60,
      backgroundColor: '#eeeeee',
      flexDirection: 'row',
      // marginLeft: 20,
  },
  card: {
      height: 60,
      width: 73,
      backgroundColor: '#fff',
      borderRadius: 5,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      marginRight: 5,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
  },
  cardPressed: {
      height: 60,
      width: 73,
      backgroundColor: '#ddd',
      borderRadius: 5,
      shadowColor: 'black',
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 6,
      marginRight: 5,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
  },
  pressable:{
      height: 60,
  },
  view: {
      height: 60,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',       
  },
  title: {
      color: '#404040',
      fontFamily: 'Raleway-Bold'
  },
  titlePressed: {
      color: '#fff',
      fontFamily: 'Raleway-Bold'
  }
})

export default GameSearchFilter;
