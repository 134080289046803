import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Button, Pressable, ScrollView, View, Text, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import screens from '../../common/constants/screen.constants';
import NextDrawCountdown from '../../components/games/NextDrawCountdown.comp';
import * as betCreatorAction from '../../store/actions/betCreator.actions';
import * as gamesActions from '../../store/actions/games.actions';
import moment from "moment";
import { LinearGradient } from 'expo-linear-gradient';
import { GameRulesLink } from '../../components/betCreation/GameRulesLink.comp';

const SessionPickerPage = ({ route }) => {

  const navigation = useNavigation();
  const { t } = useTranslation("translations");
  const theme = useTheme()
  const styles = makeStyles(theme)
  const dispatch = useDispatch();

  const currentGame = useSelector(state => state.games.currentGame);
  const currentSessions = useSelector(state => state.games.currentSessions);
  const betTypesActiveByGame = useSelector(state => state.games.betTypesActiveByGame);
  const isGameFetching = useSelector(state => state.games.isFetching);
  const selectedSessions = useSelector(state => state.betCreator.selectedSessions);

  const [allowMultiSession, setAllowMultiSession] = useState(false);
  const [ignoreBetTypeStep, setIgnoreBetTypeStep] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [hasAvailableSessions, setHasAvailableSessions] = useState(false);

  //====================================== Hooks


  useFocusEffect(
    React.useCallback(() => {
      // page enter
      if (!currentGame) {
        console.error("Session Picker Page enter: no current game");
        return navigation.navigate(screens.GamesHome);
      }
      console.log(`page enter > game ${currentGame.gameId}, currentSessions of game ${currentSessions.gameId}`);

      if (isFirstLoad || currentSessions.gameId != currentGame.gameId) {
        console.log(`Starting new bet creation > request info for game ${currentGame.gameId}`);
        dispatch(betCreatorAction.reset_data());

        const isBetTypesLoaded = betTypesActiveByGame?.[currentGame.gameId]?.lengh > 0;
        if (isBetTypesLoaded) {
          dispatch(gamesActions.getSessionsFuture(currentGame.gameType, currentGame.gameId));
        } else {
          dispatch(gamesActions.getSessionsFutureWithBetTypes(currentGame.gameType, currentGame.gameId));
        }

        setIsFirstLoad(false);
      }
      return () => {
        // page leave
      }
    }, [currentSessions])
  );


  useEffect(() => {
    //-- Manage session & betType selection mode
    /*
      case 1 : INSTANT LIVE (ex: FOGO) : don't choose sessions, only a betType, and later, the consecutive draw count
      case 2 : INSTANT DELAYED (ex: 5+2/90) : choose both sessions (multiples) & betType
      case 3 : MUTUAL CLASSIC (ex: 6/45) : choose a unique session, no betType selection (each session has its own betType)
      case 4 : MUTUAL HUB (ex: Africa Millions) : choose a unique session & betType
    */

    const _betTypeList = betTypesActiveByGame?.[currentGame.gameId] || [];

    console.log(`Game ${currentGame?.gameId}: found ${_betTypeList.length} betTypes / ${currentSessions?.list?.length} sessions`);

    if (!currentGame || currentSessions.gameId != currentGame.gameId || !currentSessions?.list?.length || _betTypeList.length == 0) {
      // still don't have fetched all the init data, or no session available
      return;
    }

    // ignore session selection step if: we need to select the consecutive draw count in a future step
    const _ignoreSessionStep = _betTypeList[0].maxDrawByBet > 1;
    // ignore the betType selection step if: only one active betType OR if the sessions are already attached to a specific betType
    const _ignoreBetTypeStep = _betTypeList.length == 1 || currentSessions?.list?.[0]?.betTypeId != undefined;
    setIgnoreBetTypeStep(_ignoreBetTypeStep);
    // multiSession only for some instant lottery games
    const _allowMultiSession = currentGame.gameType == 'instantLottery' && _ignoreSessionStep == false;

    if (_ignoreSessionStep) {
      goNextStep(_ignoreBetTypeStep, { redirect: true });
    } else {
      setAllowMultiSession(_allowMultiSession);
    }
    setHasAvailableSessions(true);

    console.log(`Bet settings: ignoreSession=${_ignoreSessionStep}, ignoreBetType=${_ignoreBetTypeStep}, allowMultiSession=${_allowMultiSession}`);

  }, [betTypesActiveByGame, currentSessions])


  //====================================== Functions

  const onSessionSelectChange = (session) => {
    if (allowMultiSession) {
      const up_selectedSessions = { ...selectedSessions };
      if (selectedSessions[session.drawId]) {
        delete up_selectedSessions[session.drawId];
      } else {
        up_selectedSessions[session.drawId] = session;
      }
      dispatch(betCreatorAction.set_sessions_selected(up_selectedSessions));
    }
    else {
      let single_session = {};
      single_session[session.drawId] = session;
      dispatch(betCreatorAction.set_sessions_selected(single_session));
      goNextStep();
    }
  }

  const onValidate = () => {
    if (Object.keys(selectedSessions).length <= 0) {
      return;
    }
    if (betTypesActiveByGame[currentGame.gameId]?.length == 1) {
      alert("1 seul betType, go directe étape de sélection des numéros");
      //navigation.navigate(screens.BetCreate_GridPicker);
    }
    if (!betTypesActiveByGame[currentGame.gameId]?.length) {
      //TODO: indiquer une erreur "aucun mode de jeu n'est disponible pour le moment"
      alert("Session Picker Page enter: no betTypes");
      return navigation.navigate(screens.GamesHome);
    }
    //TODO: forward betCreator to next step : the redux must navigate to the BetPicker step
    navigation.navigate(screens.BetCreate_BetTypePicker);
  }

  const goNextStep = (_ignoreBetTypeStep = ignoreBetTypeStep, opts = { redirect: false }) => {
    const nextStepPage = _ignoreBetTypeStep ? screens.BetCreate_GridPicker : screens.BetCreate_BetTypePicker;
    navigation.setOptions({ animation: 'none', animationEnabled: false });
    if (opts.redirect) {
      navigation.replace(nextStepPage, { options: { animation: 'none' } });
    } else {
      navigation.navigate(nextStepPage, { options: { animation: 'none' } });
    }
  }

  const goHome = () => {
    navigation.navigate(screens.GamesHome);
  }

  //====================================== Render

  const renderNoSession = () => (
    <View style={styles.noSessionContainer}>
      <Text style={styles.noSessionText}>
        {t('no_draw_available')}
      </Text>
      <Pressable style={styles.noSessionButton} onPress={goHome}>
        <Text style={styles.noSessionButtonText}>
          {t('go_back_home')}
        </Text>
      </Pressable>
    </View>
  )
  const renderSessionButton = (session, isSelected) => {

    const textStyle = isSelected ? [styles.sessionButtonText, styles.sessionButtonTextSelected] : [styles.sessionButtonText];

    return (
      <Pressable
        key={session.drawId}
        style={[styles.sessionButton, isSelected && styles.sessionButtonSelected]}
        onPress={() => onSessionSelectChange(session)}
      >
        <Text style={[...textStyle, styles.sessionButtonText_Date]}>
          {moment(session.drawingDate).format('LL - HH:mm')}
        </Text>
        {session.drawingName &&
          <Text style={[...textStyle, styles.sessionButtonText_Name]}>
            {session.drawingName}
          </Text>
        }
        <Text style={[...textStyle, styles.sessionButtonText_ClosingDate]}>
          {t('end_of_bets')} : {moment(session.closingDate).format('HH:mm')}
        </Text>
      </Pressable>
    )
  }

  const renderLoading = () => (
    <View style={styles.pageLoading}>
      <ActivityIndicator size="large" color="gray" />
      <Text>{t('loading_data')}</Text>
    </View>
  )


  return (
    <View style={styles.container}>

      {isGameFetching && renderLoading()}

      {!isGameFetching && !hasAvailableSessions && renderNoSession()}

      {!isGameFetching && hasAvailableSessions &&
        <>
          <ScrollView>

            <NextDrawCountdown />

            <View style={styles.body}>

              <GameRulesLink game={currentGame} />

              <Text style={styles.stepTitle}>
                {t(allowMultiSession ? 'select_game_sessions' : 'select_game_session')}
              </Text>

              {currentSessions.list.map(session => (
                renderSessionButton(session, selectedSessions[session.drawId])
              ))}

            </View>

          </ScrollView>

          {allowMultiSession && Object.keys(selectedSessions).length > 0 &&
            <View style={styles.validateContainer}>
              <LinearGradient
                style={styles.validateGradient}
                colors={['transparent', 'rgba(237,237,237,0.5)', 'rgba(237,237,237,1)']}
                start={{ x: 0.5, y: 0 }} end={{ x: 0.5, y: 1.0 }}
              />
              <View style={styles.validateRow}>
                <Pressable
                  style={styles.validateButton}
                  onPress={onValidate}
                >
                  <Text style={styles.validateButtonText}>
                    {t('validate')}
                  </Text>
                </Pressable>
              </View>
            </View>
          }
        </>
      }
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingHorizontal: 15,
    paddingBottom: 100, // for the validate footer
    flex: 1,
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginBottom: 10,
    color: '#404040',
  },
  //
  noSessionContainer: {
    flex: 1,
    paddingTop: 60,
    paddingHorizontal: 20,
  },
  noSessionText: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Raleway-Regular',
    color: '#000',
    marginBottom: 20,
  },
  noSessionButton: {
    backgroundColor: theme.colors.primary,
    alignItems: 'center',
    paddingVertical: 15,
    margin: 10,
    borderRadius: 4,
  },
  noSessionButtonText: {
    color: '#fff',
    fontWeight: '600',
    fontSize: 16,
  },
  pageLoading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  //
  sessionButton: {
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingVertical: 15,
    marginHorizontal: 5,
    marginVertical: 3,
    borderRadius: 4,
    elevation: 5,
  },
  sessionButtonSelected: {
    backgroundColor: theme.colors.primary,
  },
  sessionButtonText: {
    color: '#000',
    paddingBottom: 2,
  },
  sessionButtonTextSelected: {
    color: '#fff',
  },
  sessionButtonText_Date: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
  },
  sessionButtonText_Name: {
    fontFamily: 'Raleway-BoldItalic',
    fontSize: 14,
  },
  sessionButtonText_ClosingDate: {
    fontFamily: 'Raleway-Medium',
    fontSize: 14,
  },
  //
  validateContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  validateGradient: {
    height: 15,
    width: '100%',
  },
  validateRow: {
    elevation: 9,
    backgroundColor: theme.colors.background,
  },
  validateButton: {
    elevation: 6,
    backgroundColor: theme.colors.primary,
    padding: 15,
    borderRadius: 4,
    marginHorizontal: 30,
    marginVertical: 10,
    alignItems: 'center',
  },
  validateButtonText: {
    color: '#fff',
    fontWeight: '600',
    fontSize: 15,
  },
})

export const BetCreateTestLauncher = () => {

  const games = useSelector(state => state.games.games);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const onPressBetCreateTEST = async (gameType, useConsecutiveDraws) => {
    if (games.length <= 0) return;
    const gameId = gameType == 'mutualLottery' ? 2000 : useConsecutiveDraws ? 1 : 6;
    dispatch(gamesActions.set_current_game(games.find(G => G.gameId == gameId) || games.find(G => G.gameType == gameType) || games[0]));
    navigation.navigate(screens.BetCreate_SessionPicker);
  }
  return (
    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
      <Text>BetTest</Text>
      <Button title="MUTUAL" onPress={() => onPressBetCreateTEST('mutualLottery')} />
      <Button title="INSTANT FOGO" onPress={() => onPressBetCreateTEST('instantLottery', true)} />
      <Button title="INSTANT MULTI" onPress={() => onPressBetCreateTEST('instantLottery')} />
    </View>
  )
}

//======================================
export default SessionPickerPage;