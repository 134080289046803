import { useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Pressable, Modal, TextInput } from 'react-native';
import { SVGClose } from '../../assets/icons';

export default function StakePicker(props) {

  const theme = useTheme();
  const style = makeStyles(theme);

  const {
    selectedBetType,
    t,
    selectedPrice,
    setSelectedPrice,
    currency
  } = props;
  const { defaultPrices } = selectedBetType;

  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  //====================================== Functions

  function computePriceButtonClass(price) {
    return price === selectedPrice ? style.pickNumberPressed : style.pickNumber;
  }

  function computePriceTextClass(price) {
    return price === selectedPrice ? style.numberPressed : style.number;
  }

  function computeCustomAmount(value) {
      setSelectedPrice(value)
  }

  function closeCustomAmount(bool) {
    if(+selectedPrice > selectedBetType.maxBetAmount || +selectedPrice < selectedBetType.minBetAmount) {
      setErrorMessage(true)
    } else {
      setModalVisible(false)
      setErrorMessage(false)
    }
  }

  return (
    <ScrollView style={style.amountBody}>
      <Text style={style.stepTitle}>
        {t(`Amount by bet`)} ({currency})
      </Text>
      
      <View style={style.amountContainer}>
        {defaultPrices?.map((price, idx) => {
          const [priceIsPressed, setPriceIsPressed] = useState(false);
          return (
            <Pressable
              key={idx}
              onPress={() => {
                  setSelectedPrice(selectedPrice === price ? 0 : price)
                  setPriceIsPressed(!priceIsPressed)        
                }
              }
              style={computePriceButtonClass(price)}
            >
              <Text style={computePriceTextClass(price)}>
                {price}
              </Text>
            </Pressable>
          )
          }
        )}

        <Pressable
          style={style.customAmount}
          onPress={() => setModalVisible(true)}
        >
          <Text style={style.number}>
            {t(`Custom amount`)}
          </Text>
        </Pressable>

      </View>
      
      {/* Modal for custom amount selection */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          !errorMessage && setModalVisible(!modalVisible);
        }}
      >
        <View style={style.centeredView}>
          <View style={style.modalView}>
            <Pressable onPress={() => closeCustomAmount()}>
              <SVGClose />
            </Pressable>
            <Text style={style.modalText}>
              {t(`Select amount`)}
            </Text>
            <TextInput
              style={errorMessage ? style.amountInputError : style.amountInput}
              onChangeText={text => computeCustomAmount(text)}
              value={selectedPrice}
              placeholder="ex: 600..."
              keyboardType="numeric"
            />
            {errorMessage && 
              <Text style={style.errorText}>
                {t(`Select between`)} {selectedBetType.minBetAmount.toLocaleString()} {t('And')} {selectedBetType.maxBetAmount.toLocaleString()}
              </Text>
            }
        </View>
      </View>
      </Modal>
    </ScrollView>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingVertical: 18,
    paddingHorizontal: 15,
    flex: 1,
    marginHorizontal: 25
  },
  amountContainer:{
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  customAmount: {
    height: 50,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  pickNumber: {
    height: 50,
    width: 'auto',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  pickNumberPressed: {
    height: 50,
    width: 'auto',
    borderRadius: 5,
    backgroundColor: theme.colors.primary,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  number: {
    fontFamily: 'Raleway-Bold',
    fontSize: 18,
    color: theme.colors.greyText
  },
  numberPressed: {
    fontFamily: 'Raleway-Bold',
    fontSize: 18,
    color: '#ffffff'
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 18,
    marginTop: 40,
    marginBottom: 20,
    color: theme.colors.greyText,
  },
  nbDrawContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30
  },
  selectionNbBtn:{
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginHorizontal: 25,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionNbBtnLess:{
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 25,
    paddingVertical: 0
  },
  nbDraw: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nbDrawSelected: {
    fontFamily: 'Raleway-Bold',
    fontSize: 28,
    color: theme.colors.primary
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  recapTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  betTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: theme.colors.greyText,
  },
  recapValue:{
    width: '50%',
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  validateButton: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    marginBottom: 10
  },
  disabledValidateButton: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    opacity: 0.6,
    marginBottom: 10
  },
  textButton: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
  betAmount: {
    backgroundColor: '#FFFFFF',
    width: '90%',
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 37,
    justifyContent: 'center',
    paddingLeft: 15
  },
  //Modal
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    width: '70%',
    backgroundColor: "white",
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 35,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  closeModal: {
    top: 5,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    marginBottom: 15,
    marginTop: 15,
    textAlign: "center"
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  amountInput: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    borderBottomColor: theme.colors.greyText,
    borderBottomWidth: 1,
    width: '90%',
    marginBottom: 30
  },
  amountInputError: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    borderBottomColor: "#FF0000",
    borderBottomWidth: 1,
    width: '90%',
    marginBottom: 30
  },
  errorText: {
    fontFamily: 'Raleway-Bold',
    color: "#FF0000",
    marginBottom: 15,
    marginTop: 15,
    textAlign: "center"
  },
})
