import { ACTIONS } from '../actions/games.actions';

// initial state of this reducer

const initialState = {
  error: false,
  isFetching: false,

  // games
  games: [],
  currentGameId: undefined,
  currentGame: {},

  // sessions
  sessionsInstantFutureAll: [], // = the active sessions (all games)
  sessionsMutualFutureAll: [], // = the active sessions (all games)
  currentSessions: { gameId: null, list: [] }, // = all the active sessions of the current game

  // betTypes
  currentBetType: undefined,
  betTypesMap: {},
  betTypesByGame: {}, // {gameId1: BetType[]}
  betTypesActiveByGame: {}, // {gameId1: BetType[]}
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function gamesReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.GAMES_ERROR: {
      console.warn("Games action error : " + JSON.stringify(action.payload));
      return { ...state, error: action.payload, isFetching: false }
    }
    case ACTIONS.GAMES_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.GAMES_FETCHING_DONE: {
      return { ...state, isFetching: false }
    }
    case ACTIONS.GAMES_RESET_SEARCH_STATE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    case ACTIONS.GAMES_BETTYPES_BY_GAME: {
      let betTypesByGame = { ...state.betTypesByGame };
      let betTypesActiveByGame = { ...state.betTypesActiveByGame };
      betTypesByGame[action.payload.gameId] = action.payload.betTypes;
      betTypesActiveByGame[action.payload.gameId] = (action.payload.betTypes || []).filter(E => E.status == 'ENABLED');
      return { ...state, betTypesByGame, betTypesActiveByGame }
    }
    case ACTIONS.GAMES_BETTYPE_DETAILS: {
      let betTypesMap = { ...state.betTypesMap };
      betTypesMap[action.payload.betTypeId] = action.payload.betType;
      return { ...state, betTypesMap }
    }
    case ACTIONS.GAMES_CURRENT_GAME: {
      return { ...state, currentGame: action.payload }
    }
    case ACTIONS.GAMES_CURRENT_BETTYPE: {
      return { ...state, currentBetType: action.payload }
    }
    case ACTIONS.GAMES_CURRENT_SESSIONS: {
      return { ...state, currentSessions: action.payload }
    }
    case ACTIONS.GAMES_LIST: {
      return { ...state, games: action.payload }
    }
    case ACTIONS.GAMES_SESSIONS_INSTANT_FUTURE_ALL: {
      return { ...state, sessionsInstantFutureAll: action.payload }
    }
    case ACTIONS.GAMES_SESSIONS_MUTUAL_FUTURE_ALL: {
      return { ...state, sessionsMutualFutureAll: action.payload }
    }
    default:
      return state
  }
}