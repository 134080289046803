import { View, Text, StyleSheet, ScrollView } from "react-native";
import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { getBetStatus, getDrawStatusColor, getStatusColor } from '../../utils/StatusUtils';
import { drawDateFormat } from "../../utils/DateUtils";

export default function InstantLottery({borderColor, betDetails}) {

  const { t } = useTranslation("translations");
  const theme = useTheme();
  const style = makeStyles(borderColor, drawStatusColor, theme);

  return (
    <View>
      {betDetails.listOfStatusAndDraws.map((draw, index) => {

        const [drawStatusColor, setDrawStatusColor] = useState(getDrawStatusColor(draw.status));
        const [drawsEnded, setDrawsEnded] = useState(false);

        useEffect(() => {
          setDrawsEnded(draw.status !== 'wait' && draw.status !== 'canceledTicket');
        }, []);

        function getBallClass(ball) {
          const findGrid = draw?.draw?.listOfGridDrawn && draw?.draw?.listOfGridDrawn[0].winningNumbers;
          return findGrid && findGrid.includes(ball) ? style.ballDrawed : style.ballPulled;
        }

        function getNumberClass(ball) {
          const findGrid = draw?.draw?.listOfGridDrawn && draw?.draw?.listOfGridDrawn[0].winningNumbers;
          return findGrid && findGrid.includes(ball) ? style.numberDrawed : style.numberPulled;
        }

        return (
          <View key={index}>
            <View style={style.drawPart}>

            <View style={style.drawInfoPart}>
              <Text style={style.drawInfosText} numberOfLines={1} ellipsizeMode='tail'>
              {t('Draw')} {draw.draw?.drawId}
              </Text>
              <View style={style.drawInfosText}>
                <Text style={style.drawInfosStatus}>
                  {t(getBetStatus(draw?.status))}
                </Text>
              </View>
              <Text style={style.drawInfosDate} numberOfLines={1} ellipsizeMode='tail'>
                {drawDateFormat(draw.draw?.drawingDate)}
              </Text>
            </View>
      
            <View style={style.drawNamePart}>
              <View style={style.separator}></View>
            </View>
            
            {draw.status === 'wait' || betDetails.listOfBetGrids?.map((grid, index) => (
                <View key={index} style={style.drawSelection}>
                  <View style={style.leftColumnSelection}>
                    <Text style={style.leftTextSelection}>
                      {betDetails.listOfBetGrids?.length > 1 ? index === 0 && 'A' || index === 1 && 'B'  : 'Selection'}
                    </Text>
                  </View>
                  <View style={style.rightColumnSelection}>
                    {grid.listOfLottoNumbers?.map((ball, index) => (
                      <View key={index} style={drawsEnded ? getBallClass(ball) : style.ballPending}>
                        <Text style={drawsEnded ? getNumberClass(ball) : style.numberPending}>{ball}</Text>
                      </View>
                    ))}
                  </View>
                </View>               
              )
            )}

          </View>

          {
            drawsEnded && (
            <View style={style.drawNumberPart}>
              <Text style={style.drawNumberText}>{t('Draw')} : </Text>
              <View style={style.drawNumberMap}>
                {draw.draw?.listOfGridDrawn?.map((grid, index) => (
                  <View key={index} style={style.drawNumberContainer}>
                    <Text style={style.drawNumberText}>({index === 0 && 'A' || index === 1 && 'B'}) </Text>
                    {grid.winningNumbers?.map((numb, index) => (
                      <Text key={index} style={style.drawNumberText}>{numb}{numb === grid.winningNumbers[grid.winningNumbers.length - 1] ? ' ' : ', '}</Text>
                    ))}                    
                  </View>
                ))}
              </View>
            </View>              
            )
          }

        </View>
        )
      })}
    </View>
  );
}


//====================================== STYLE
const makeStyles = (borderColor, drawStatusColor, theme) => StyleSheet.create({
  // Draw part 
  drawPart: {
    marginTop: 15,
    backgroundColor: '#F6F6F6',
    marginHorizontal: 15,
    paddingVertical: 8,
    paddingHorizontal: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1.71,

    elevation: 3,
  },
  drawInfoPart: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  drawInfosText: {
    fontSize: 12,
    fontFamily: 'Raleway-SemiBold',
    color: theme.colors.greyText,
    width: '30%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  drawInfosDate: {
    fontSize: 12,
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    width: '25%',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  drawInfosStatus: {
    fontSize: 12,
    fontFamily: 'Raleway-SemiBold',
    color: drawStatusColor.text,
    backgroundColor: drawStatusColor.background,
    paddingHorizontal: 6,
    paddingVertical: 1,
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawNamePart: {
    marginTop: 7,
    width: '100%',
    flexDirection: 'column'
  },
  drawNameText: {
    fontSize: 12,
    fontFamily: 'Raleway-LightItalic',
    color: theme.colors.greyText,
    width: '100%',
  },
  separator: {
    marginTop: 5,
    width: '100%',
    height: 1,
    backgroundColor: '#112F6C',
    marginBottom: 10
  },
  // Draw Selection
  drawSelection: {
    marginTop: 7,
    width: '100%',
    flexDirection: 'row'
  },
  leftColumnSelection: {
    marginLeft: 5,
    width: '7%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: 7
  },
  rightColumnSelection: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 7,
    flexWrap: 'wrap'
  },
  leftTextSelection: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 13,
  },
  drawNumberPart: {
    backgroundColor: theme.colors.primary,
    marginHorizontal: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1.71,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 14,
    paddingVertical: 9
  },
  drawNumberText: {
    fontFamily: 'Raleway-Bold',
    color: '#FFFFFF',
    fontSize: 12,
  },
  drawNumberMap: {
    flexDirection: 'row',
  },
  drawNumberContainer: {
    flexDirection: 'row',
  },
  // Ball Numbers
  ballPending: {
    width: 28,
    height: 28,
    borderColor: theme.colors.greyText,
    borderWidth: 1,
    borderRadius: 30,
    marginRight: 2,
    marginBottom: 3,
    alignItems: 'center'
  },
  numberPending: {
    color: theme.colors.greyText,
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  },
  ballPulled: {
    width: 28,
    height: 28,
    borderRadius: 30,
    marginRight: 2,
    marginBottom: 3,
    alignItems: 'center',
    backgroundColor: '#EDEDED'
  },
  numberPulled: {
    color: '#8F8E95',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  },
  ballDrawed: {
    width: 28,
    height: 28,
    borderRadius: 30,
    marginRight: 2,
    marginBottom: 3,
    alignItems: 'center',
    backgroundColor: '#30C408'
  },
  numberDrawed: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  }
});

