import React from 'react';
import { Text } from 'react-native';
import { useSelector } from 'react-redux';
import SessionPickerPage from '../../screens/betCreation/SessionPicker.page';
import BetTypePickerPage from '../../screens/betCreation/BetTypePicker.page';
import screens from '../../common/constants/screen.constants';
import GridPicker from '../../screens/betCreation/GridPicker.page';
import Basket from '../../screens/basket/Basket';

import TicketPage from '../../screens/ticket/Ticket.page';

import GainsDetails from '../../screens/ticket/GainsDetails.page';

const FullScreenNavigationGroups = (Stack) => {

  const currentGame = useSelector(state => state.games.currentGame);

  const betCreateScreenOptions = () => ({
    title: currentGame?.name,
    // animation: 'none',
    headerRight: ({ tintColor }) => (
      <Text style={{ color: tintColor }}>Solde joueur FCFA</Text>
    )
  });

  const basketScreenOptions = () => ({
    title: 'Mon panier',
    // animation: 'none',
    // headerRight: ({ tintColor }) => (
    //   <Text style={{ color: tintColor }}>Solde joueur FCFA</Text>
    // )
  });

  return (
    <React.Fragment>
      {/* Bet Creation */}
      <Stack.Group screenOptions={betCreateScreenOptions()}>
        <Stack.Screen name={screens.BetCreate_SessionPicker} component={SessionPickerPage} />
        <Stack.Screen name={screens.BetCreate_BetTypePicker} component={BetTypePickerPage} options={{ animation: 'none' }} />
        <Stack.Screen name={screens.BetCreate_GridPicker} component={GridPicker} />
      </Stack.Group>
      {/* Basket */}
      <Stack.Group screenOptions={basketScreenOptions()}>
        <Stack.Screen name={screens.basket} component={Basket} />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen name={screens.Ticket} component={TicketPage} options={{title: 'My ticket'}} />
        <Stack.Screen name={screens.GainsDetails} component={GainsDetails} options={{title: 'Gains details'}} />
      </Stack.Group>
    </React.Fragment>
  )
}

export default FullScreenNavigationGroups;