import React, { useState } from "react";
import { useNavigation, useTheme } from '@react-navigation/native';
import { dateFirstFormat } from '../../utils/DateUtils';
import { View, Text, StyleSheet, ScrollView, Image, Pressable } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import screens from "../../common/constants/screen.constants";
import * as GamesActions from '../../store/actions/games.actions';

function PreviewGameList(props) {
  const { t, title, sessions, navigationScreen } = props;
  const navigation = useNavigation();
  const theme = useTheme();
  const style = makeStyles(theme);
  const dispatch = useDispatch();

  const games = useSelector(state => state.games.games);

  const goToGameList = (sessions) => {
    navigation.navigate(navigationScreen, { sessions });
  }

  const onSessionSelected = (session) => {
    const game = games.find(G => G.gameId == session.gameId);
    if (game) {
      dispatch(GamesActions.set_current_game(game));
      navigation.navigate(screens.BetCreate_SessionPicker);
    } else {
      console.error("the game associated to the session is not found");
    }
  }

  return (
      <View style={style.container}>
        <View style={style.topContainer}>
          <Text style={style.leftTitle}>
            {t(title)}
          </Text>
          <Pressable onPress={() => goToGameList(sessions)}>
            <Text style={style.rightTitle}>
              {t('See all')}
            </Text>               
          </Pressable>   
        </View>
          <ScrollView horizontal={true} style={style.cardContainer}>
          {sessions?.map((session, index) => ( session && 
            <Pressable key={index} style={style.card} onPress={() => onSessionSelected(session)}>
              <Image style={style.cardImage} source={require('../../assets/images/Game_Default_Thumnail.png')} />
              <View style={style.cardText}>
                <Text style={style.gameName} numberOfLines={1} ellipsizeMode='tail'>{session.gameName || 'Game name'}</Text>
                <Text style={style.gameGain}>5000 FCFA</Text>
                <Text style={style.gameDate}>{dateFirstFormat(session.drawingDate)}</Text>                  
              </View>
            </Pressable>
            ))
          }         
        </ScrollView>
      </View>
    );

}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    height: 205,
    backgroundColor: '#eeeeee',
    marginTop: 10,
    width: '100%',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 17,
    color: '#404040',
  },
  rightTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 17,
    color: theme.colors.clearGrey,
  },
  card: {
    height: 158,
    width: 146,
    borderRadius: 3,
    backgroundColor: "#ffffff",
    marginRight: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  cardContainer: {
    paddingTop: 10,
    flexDirection: 'row',
  },
  cardImage: {
    height: 84,
    width: 146,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  cardText: {
    paddingHorizontal: 10,
    paddingTop: 5,
  },
  gameName: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 12,
    paddingBottom: 7
  },
  gameGain: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 13
  },
  gameDate: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 12
  }
})

export default PreviewGameList;
