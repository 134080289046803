import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import { getLocales } from 'react-native-localize';
import * as Localization from 'expo-localization';

import AsyncStoragePlugin from 'i18next-react-native-async-storage';

import { translationFR } from './translation.fr';
import { translationEN } from './translation.en';
import { translationPT } from './translation.pt';

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/pt';

const getI18n = () => {

  const detectUserLanguage = (callback) => {
    // const locale_list = getLocales();
    // const locale_used = locale_list?.length > 0 ? locale_list[0].languageCode : 'pt';
    const locale_used = Localization.locale.substring(0, 2) || 'pt';
    console.log("User Language : " + locale_used);
    callback(locale_used);
    moment.locale(locale_used);
  }

  i18n
    .use(initReactI18next)
    .use(AsyncStoragePlugin(detectUserLanguage))
    .init({
      resources: {
        fr: { translations: translationFR },
        en: { translations: translationEN },
        pt: { translations: translationPT },
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ","
      },
      react: {
        useSuspense: true,
      },
      compatibilityJSON: 'v3',
      keySeparator: false,
    });

  return i18n;
}

export default getI18n();
