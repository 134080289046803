import { useTheme } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View, Text, StyleSheet } from 'react-native';
import screens from "../../common/constants/screen.constants";

import { SVGInformationCircle } from '../../assets/icons';

export const GameRulesLink = ({game}) => {

  const { t } = useTranslation("translations");
  const theme = useTheme();
  const styles = makeStyles(theme);

  const onGameRulesHelp = () => {
    //TODO: onGameRulesHelp : navigate to a modal page or display a popup, 
    // maybe download a game help image, or display a HTML section defined in graphQL
    // navigation.navigate(screens.GameRulesHelp);
  }

  return (
    <View style={styles.container}>
      <Pressable style={styles.gameRulesButton} onPress={onGameRulesHelp}>
        <SVGInformationCircle fill={theme.colors.primary} style={{ width: 24 }} />
        <Text style={styles.gameRulesText}>
          {t('game_rules')} {game?.name}
        </Text>
      </Pressable>
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    marginTop: 6,
    marginBottom: 12,
    justifyContent: 'flex-start',
  },
  gameRulesButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  gameRulesText: {
    color: theme.colors.primary,
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    paddingLeft: 5,
  },
})

//======================================
