const screens = {
  BetCreate_SessionPicker: 'BetCreate_SessionPicker',
  BetCreate_BetTypePicker: 'BetCreate_BetTypePicker',
  BetCreate_GridPicker: 'BetCreate_GridPicker',
  //
  Games: 'Games', //Tab root
  IncomingDrawListScreen: 'IncomingDrawListScreen',
  MutualListScreen: 'MutualListScreen',
  GamesHome: 'GamesHome',
  MyBets: 'MyBets',
  MySpace: 'MySpace',
  Results: 'Results',
  Scan: 'Scan',
  ScanHelp: 'ScanHelp',
  ScanHome: 'ScanHome',
  ScanManual: 'ScanManual',
  ScanPreview: 'ScanPreview',
  Ticket: 'Ticket',
  GainsDetails: 'GainsDetails',
  UserFavorites: 'UserFavorites',

  basket: 'Basket'
}
export default screens;