import { CommonActions } from '@react-navigation/native';

let navigator;

export const setTopLevelNavigator = navigatorRef => {
  navigator = navigatorRef;
};

export const navigate = (screen, params) => {
  navigator.dispatch(
    CommonActions.navigate(screen, params)
  );
};
