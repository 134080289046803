import React, { useEffect, useState } from "react";
import { useNavigation, useTheme } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { dateSecondFormat } from '../../utils/DateUtils';
import { View, Text, StyleSheet, ScrollView, Image, Pressable } from 'react-native';
import * as GamesActions from '../../store/actions/games.actions';
import screens from '../../common/constants/screen.constants';


function DrawGameList(props) {
  const { sessions } = props;
  const games = useSelector(state => state.games.games);
  const navigation = useNavigation();
  const theme = useTheme();
  const style = makeStyles(theme);
  const dispatch = useDispatch();

  const onSessionSelected = (session) => {
    const game = games.find(G => G.gameId == session.gameId);
    if (game) {
      dispatch(GamesActions.set_current_game(game));
      navigation.navigate(screens.BetCreate_SessionPicker);
    } else {
      console.error("the game associated to the session is not found");
    }
  }

  return (
    <ScrollView>
      {sessions.map((session, index) => (session &&
        <Pressable key={index} style={style.card} onPress={() => onSessionSelected(session)}>
          <Image style={style.cardImage} source={require('../../assets/images/Game_Default_Thumnail.png')} />
          <View style={style.cardText}>
            <Text style={style.gameName} numberOfLines={1} ellipsizeMode='tail'>{session.gameName}</Text>
            <Text style={style.gameGain}>9999 FCFA</Text>
            <Text style={style.gameDate}>{dateSecondFormat(session.drawingDate)}</Text>
          </View>
        </Pressable>
      ))
      }
    </ScrollView>
  );
}

const makeStyles = (theme) => StyleSheet.create({
  card: {
    flexDirection: 'row',
    marginBottom: 12,
    height: 75,
    borderRadius: 3,
    backgroundColor: "#ffffff",
    marginRight: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  cardImage: {
    height: 75,
    width: 108,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  cardText: {
    paddingHorizontal: 10,
    paddingTop: 5,
    width: 232
  },
  gameName: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 12,
    paddingBottom: 3
  },
  gameGain: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 13,
    marginBottom: 5
  },
  gameDate: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 12
  }
})

export default DrawGameList;
