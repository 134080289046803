import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { useEffect, useState } from 'react';

import store from './src/store/index';
import { Platform } from 'react-native';
import * as navigationService from './src/store/services/navigation.service';
import './src/common/i18n/i18n';
import { AppTheme } from './src/common/theme/theme';
import { initToken } from './src/store/actions/login.actions';
import Main from './src/components/Main';
import AppLoader from './src/components/AppLoader';

import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';

export default function AppContainer() {

  return (
    <NavigationContainer
      theme={AppTheme}
      ref={navigatorRef => navigationService.setTopLevelNavigator(navigatorRef)}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </NavigationContainer>
  );
}

export function App() {

  const isKeycloackLogged = useSelector(state => state.login.isKeycloackLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('User platform is',Platform.OS)
    initToken(dispatch);
  }, []);

  const [ fontsLoaded, setFontsLoaded ] = useState(false);

  // Fetching fonts for all the app
  const fetchFonts = async () =>
    await Font.loadAsync({
      'Raleway-Regular': require('./assets/fonts/Raleway/Raleway-Regular.ttf'),
      'Raleway-Medium': require('./assets/fonts/Raleway/Raleway-Medium.ttf'),
      'Raleway-SemiBold': require('./assets/fonts/Raleway/Raleway-SemiBold.ttf'),
      'Raleway-Bold': require('./assets/fonts/Raleway/Raleway-Bold.ttf'),
      'Raleway-LightItalic': require('./assets/fonts/Raleway/Raleway-LightItalic.ttf'),
      'Raleway-BoldItalic': require('./assets/fonts/Raleway/Raleway-BoldItalic.ttf'),
      'Raleway-MediumItalic': require('./assets/fonts/Raleway/Raleway-MediumItalic.ttf'),
  });

  return (
    <>
      { 
        !fontsLoaded ?
          <AppLoading
            startAsync={fetchFonts}
            onError={console.warn}
            onFinish={() => setFontsLoaded(true)}
          />  :
          isKeycloackLogged ?
            <Main /> :
            <AppLoader />
      }
    </>
    );
}