import * as service from "../services/games.service";

// Definition of the actions name

export const ACTIONS = {
  GAMES_ERROR: 'GAMES_ERROR',
  GAMES_FETCHING: 'GAMES_FETCHING',
  GAMES_FETCHING_DONE: 'GAMES_FETCHING_DONE',
  //
  GAMES_BETTYPES_BY_GAME: 'GAMES_BETTYPES_BY_GAME',
  GAMES_BETTYPE_DETAILS: 'GAMES_BETTYPE_DETAILS',
  GAMES_CURRENT_GAME: 'GAMES_CURRENT_GAME',
  GAMES_CURRENT_SESSIONS: 'GAMES_CURRENT_SESSIONS',
  GAMES_CURRENT_BETTYPE: 'GAMES_CURRENT_BETTYPE',
  GAMES_LIST: 'GAMES_LIST',
  GAMES_SESSIONS_INSTANT_FUTURE_ALL: 'GAMES_SESSIONS_INSTANT_FUTURE_ALL',
  GAMES_SESSIONS_MUTUAL_FUTURE_ALL: 'GAMES_SESSIONS_MUTUAL_FUTURE_ALL',
}

// Dispatchers

// Lifecycle
export const set_state_error = (err) => {
  return { type: ACTIONS.GAMES_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.GAMES_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.GAMES_FETCHING_DONE };
}
//
export const set_current_betType = (betType) => {
  return { type: ACTIONS.GAMES_CURRENT_BETTYPE, payload: betType };
}
export const set_current_game = (game) => {
  return { type: ACTIONS.GAMES_CURRENT_GAME, payload: game };
}
export const set_bettype_details = (betTypeId, betType) => {
  return { type: ACTIONS.GAMES_BETTYPE_DETAILS, payload: { betTypeId, betType } };
}
export const set_current_sessions = (gameId, list) => {
  return { type: ACTIONS.GAMES_CURRENT_SESSIONS, payload: { gameId, list } };
}
export const set_game_bettype_list = (gameId, betTypes) => {
  return { type: ACTIONS.GAMES_BETTYPES_BY_GAME, payload: { gameId, betTypes } };
}
export const set_game_list = (games) => {
  return { type: ACTIONS.GAMES_LIST, payload: games };
}
export const set_sessions_instant_future_all = (sessions) => {
  return { type: ACTIONS.GAMES_SESSIONS_INSTANT_FUTURE_ALL, payload: sessions };
}
export const set_sessions_mutual_future_all = (sessions) => {
  return { type: ACTIONS.GAMES_SESSIONS_MUTUAL_FUTURE_ALL, payload: sessions };
}

// Actions

// GAMES

export const getGames = () => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getGames();
      if (r.data?.listOfGames?.length > 0) {
        dispatch(set_game_list(r.data?.listOfGames))
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

// BET TYPES

export const getBetTypeList = (gameType, gameId) => {
  if (gameType == 'instantLottery')
    return getInstantBetTypeList(gameId);
  else
    return getMutualBetTypeList(gameId);
}

export const getInstantBetTypeList = (gameId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getInstantBetTypeList(gameId);
      if (r.data?.betTypes?.length > 0) {
        dispatch(set_game_bettype_list(gameId, r.data?.betTypes))
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getInstantBetType = (gameId, betTypeId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getInstantBetType(gameId, betTypeId);
      if (r.data?.betType) {
        dispatch(set_bettype_details(betTypeId, r.data?.betType));
        dispatch(set_current_betType(r.data?.betType));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getMutualBetTypeList = (gameId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getMutualBetTypeList(gameId);
      //for Mutual, we get all the betType info, not only the names
      dispatch(set_game_bettype_list(gameId, r.data?.betTypes))
      r.data?.betTypes?.map(betType => {
        dispatch(set_bettype_details(betType.betTypeId, betType));
      });
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

// SESSIONS

export const getSessionsFuture = (gameType, gameId, max, before, after) => {
  if (gameType == 'instantLottery')
    return getInstantSessionsFuture(gameId, max, before, after);
  else
    return getMutualSessionsFuture(gameId, max, before, after);
}

export const getInstantSessionsFuture = (gameId, max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getInstantSessionsFuture(gameId, max, before, after);
      dispatch(set_current_sessions(gameId, r.data?.listOfDraws))
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getMutualSessionsFuture = (gameId, max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getMutualSessionsFuture(gameId, max, before, after);
      dispatch(set_current_sessions(gameId, r.data?.listOfDraws))
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const getAllSessionsFuture = () => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      await Promise.all([
        service.getAllInstantSessionsFuture().then(r => {
          dispatch(set_sessions_instant_future_all(r.data?.listOfDraws))
        }),
        service.getAllMutualSessionsFuture().then(r => {
          dispatch(set_sessions_mutual_future_all(r.data?.listOfDraws))
        }),
      ])
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const getAllInstantSessionsFuture = (max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getAllInstantSessionsFuture(max, before, after);
      dispatch(set_sessions_instant_future_all(r.data?.listOfDraws))
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getAllMutualSessionsFuture = (max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getAllMutualSessionsFuture(max, before, after);
      dispatch(set_sessions_mutual_future_all(r.data?.listOfDraws))
      alert(JSON.stringify(r.data?.listOfDraws, null, 2));
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

// SESSIONS WITH BETTYPES (combined)

export const getSessionsFutureWithBetTypes = (gameType, gameId, max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      const futureFunc = gameType == 'instantLottery' ? service.getInstantSessionsFuture : service.getMutualSessionsFuture;
      const betTypeFunc = gameType == 'instantLottery' ? service.getInstantBetTypeList : service.getMutualBetTypeList;
      await Promise.all([
        futureFunc(gameId, max, before, after).then(r => {
          dispatch(set_current_sessions(gameId, r.data?.listOfDraws))
        }),
        betTypeFunc(gameId).then(r => {
          dispatch(set_game_bettype_list(gameId, r.data?.betTypes))
        }),
      ])
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

/**
 * Usage: return dispatch => HANDLE_CALL(dispatch, () => PromiseFunction)
 */
export const HANDLE_CALL = async (dispatch, callback) => {
  try {
    dispatch(set_state_fetching());
    await callback();
    dispatch(set_state_fetching_done());
  } catch (err) {
    dispatch(set_state_error(err));
  }
}