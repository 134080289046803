import { DefaultTheme } from "@react-navigation/native";

export const AppTheme = {
  ...DefaultTheme,
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    primary: '#2655f9',
    background: '#ededed',
    greyText: '#404040',
    clearGrey: '#9F9F9F'
    // card: 'rgb(255, 255, 255)',
    // text: 'rgb(28, 28, 30)',
    // border: 'rgb(199, 199, 204)',
    // notification: 'rgb(255, 69, 58)',
  },
};

export const styleStackScreenOptions = {
  headerStyle: { backgroundColor: AppTheme.colors.primary },
  headerTintColor: "#fff",
}