import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, View, Text, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import screens from '../../common/constants/screen.constants';
import NextDrawCountdown from '../../components/games/NextDrawCountdown.comp';
import * as betCreatorAction from '../../store/actions/betCreator.actions';
import { GameRulesLink } from '../../components/betCreation/GameRulesLink.comp';

const BetTypePickerPage = () => {

  const navigation = useNavigation();
  const { t } = useTranslation("translations");
  const theme = useTheme()
  const styles = makeStyles(theme)
  const dispatch = useDispatch();

  const selectedBetType = useSelector(state => state.betCreator.selectedBetType);
  const currentGame = useSelector(state => state.games.currentGame);
  const betTypesActiveByGame = useSelector(state => state.games.betTypesActiveByGame);

  const [betTypeList, setBetTypeList] = useState([]);
  const [betTypeButtonWidth, setBetTypeButtonWidth] = useState('50%');

  //====================================== Hooks

  useFocusEffect(
    React.useCallback(() => {
      // page enter
      if (!currentGame) {
        console.error("BetType Picker Page enter: no current game");
        return navigation.navigate(screens.GamesHome);
      }
      console.log(`BetType page enter > game ${currentGame.gameId}, betTypes ${betTypesActiveByGame?.[currentGame.gameId]?.lengh}`);

      return () => {
        // page leave
      }
    }, [betTypesActiveByGame])
  );

  useEffect(() => {
    const _betTypeList = betTypesActiveByGame?.[currentGame.gameId] || [];

    setBetTypeList(betTypesActiveByGame?.[currentGame.gameId] || []);

    const nameMaxLength = _betTypeList.reduce((max, betType) => Math.max(betType.name.length, max), 0);
    setBetTypeButtonWidth(nameMaxLength > 8 ? '50%' : '33%');

  }, [betTypesActiveByGame]);


  //====================================== Functions

  const onBetTypeSelect = (betType) => {
    dispatch(betCreatorAction.set_betType_selected(betType));
    navigation.navigate(screens.BetCreate_GridPicker);
  }

  //====================================== Render

  const renderBetTypeButton = (betType, isSelected) => {

    const textStyle = isSelected ? [styles.betTypeButtonText, styles.betTypeButtonTextSelected] : [styles.betTypeButtonText];

    return (
      <View
        style={[ styles.betTypeButtonBox, {width: betTypeButtonWidth} ]}
        key={betType.betTypeId}
      >
        <Pressable
          style={[styles.betTypeButton, isSelected && styles.betTypeButtonSelected]}
          onPress={() => onBetTypeSelect(betType)}
        >
          <Text style={textStyle}>
            {betType.name}
          </Text>
        </Pressable>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView>

      <NextDrawCountdown />

      <View style={styles.body}>

        <GameRulesLink game={currentGame} />

        <Text style={styles.stepTitle}>
          {t('select_bet_type')}
        </Text>

        <View style={styles.betTypeListContainer}>
          {betTypeList.map(betType => (
            renderBetTypeButton(betType, selectedBetType?.betTypeId == betType.betTypeId)
          ))}
        </View>

      </View>

      </ScrollView>
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingHorizontal: 15,
    flex: 1,
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginBottom: 10,
    color: '#404040',
  },
  //
  betTypeListContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  betTypeButtonBox: {
  },
  betTypeButton: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 15,
    margin: 5,
    borderRadius: 4,
    elevation: 5,
  },
  betTypeButtonSelected: {
    backgroundColor: theme.colors.primary,
  },
  betTypeButtonText: {
    overflow: 'hidden',
    color: '#000',
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Raleway-Bold',
    paddingBottom: 2,
  },
  betTypeButtonTextSelected: {
    color: '#fff',
  },
})

//======================================
export default BetTypePickerPage;