import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from 'react-native';

import { ticketDateFormat } from '../../utils/DateUtils';
import { getBetStatus, getStatusColor } from '../../utils/StatusUtils';

export default function TicketSummary({ticket, t, gameName, currency}) {

  const [computeColor, setComputeColor] = useState('#404040');
  const [nbDrawsPending, setNbDrawsPending] = useState(0);
  const [nbDrawsMade, setNbDrawsMade] = useState(0);

  const style = makeStyles(computeColor);

  useEffect(() => {
    setComputeColor(getStatusColor(ticket))
    computeDrawsMade();
  }, [ticket])

  useEffect(() => {
    computeDrawsPending();
  }, [nbDrawsMade]);
  

  function computeDrawsMade() {
    let nbDraws = 0;
    ticket.listOfBets?.forEach((bet) => {
      if (bet.gameType === 'mutualLottery') {
        bet.draw.status === 'end' ? nbDraws += 1 : nbDraws += 0;
      } else {
        nbDraws = nbDraws + bet.listOfStatusAndDraws.filter(draw => draw.status !== 'wait').length;
      }
    })
    setNbDrawsMade(nbDraws)
  }

  function computeDrawsPending() {
    let nbDraws = 0;
    ticket.listOfBets?.forEach((bet) => {
      if (bet.gameType === 'mutualLottery') {
        bet.draw.status === 'end' ? nbDraws += 0 : nbDraws += 1;
      } else {
        nbDraws = nbDraws + bet.numberOfDraws;
      }
    })
    setNbDrawsPending(nbDraws === 0 ? nbDraws : nbDraws - nbDrawsMade)
  }

  return (
    <View>
      <View style={style.summaryTicket}>

          <Text style={style.date}>
            {ticketDateFormat(ticket.creationTime)}
          </Text>
          <Text style={style.gameName}>
            {gameName}
          </Text>

          <View style={style.gridSummary}>
            <View style={style.leftColumn}>
              <Text style={style.leftText}>
                {t('Ticket')} n° :
              </Text>
            </View>
            <View style={style.rightColumn}>
              <Text style={style.rightText}>
                {ticket.reference}
              </Text>
            </View>
          </View>
          <View style={style.gridSummary}>
            <View style={style.leftColumn}>
              <Text style={style.leftText}>
              {t('Status')} :
              </Text>
            </View>
            <View style={style.rightColumn}>
              <Text style={style.rightText}>
                <View style={getBetStatus(ticket.status) === 'Pending' ?
                        style.statusBallPending :
                        style.statusBallEnded}></View> {t(getBetStatus(ticket.status))}
              </Text>
            </View>
          </View>
          <View style={style.gridSummary}>
            <View style={style.leftColumn}>
              <Text style={style.leftText}>
              {t('Total bet')} :
              </Text>
            </View>
            <View style={style.rightColumn}>
              <Text style={style.rightText}>
                {ticket.amtTotalBet.toLocaleString()} {currency}
              </Text>
            </View>
          </View>
          <View style={style.gridSummary}>
            <View style={style.leftColumn}>
              <Text style={style.leftText}>
              {t('Draws made')} :
              </Text>
            </View>
            <View style={style.rightColumn}>
              <Text style={style.rightText}>
                {nbDrawsMade}
              </Text>
            </View>
          </View>
          <View style={style.gridSummary}>
            <View style={style.leftColumn}>
              <Text style={style.leftText}>
              {t('Draws pending')} :
              </Text>
            </View>
            <View style={style.rightColumn}>
              <Text style={style.rightText}>
                {nbDrawsPending}
              </Text>
            </View>
          </View>
        </View>
      <View style={style.gridGains}>
        <View style={style.leftColumn}>
          <Text style={style.leftGainsText}>
          {t('Total gains')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightGainsText}>
            {ticket.amtTotalWon.toLocaleString()} {currency}
          </Text>
        </View>
      </View>
    </View>
  );
}


//====================================== STYLE
const makeStyles = (computeColor) => StyleSheet.create({
  summaryTicket: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingVertical: 15,
    alignItems: 'center'
  },
  date: {
    paddingBottom: 10,
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13
  },
  gameName: {
    paddingBottom: 14,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    fontSize: 22,
    textTransform: 'uppercase'
  },
  // Grid Part
  gridSummary: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 7
  },
  leftColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: 7
  },
  leftText: {
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13,
  },
  rightColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 7,
    justifyContent: 'center'
  },
  rightText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#404040',
    fontSize: 15
  },
  // Status Ball
  statusBallPending: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#FE1593',
  },
  statusBallEnded: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#A9A9A9',
  },
  // Total Gains Summary
  gridGains: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
    backgroundColor: computeColor,
    marginBottom: 10
  },
  leftGainsText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#ffffff',
    fontSize: 16,
  },
  rightGainsText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#ffffff',
    fontSize: 20,
  },
});
