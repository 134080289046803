import { useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Pressable, TouchableOpacity, Modal, TextInput } from 'react-native';
import {
  SVGLess,
  SVGAdd
} from '../../assets/icons';

export default function ConsecutiveDrawPicker(props) {
  
  const theme = useTheme();
  const style = makeStyles(theme);

  const {
    selectedBetType,
    t,
    nbDrawSelected,
    setNbDrawSelected,
  } = props;
  const { maxDrawByBet } = selectedBetType;

  return (
    <View style={style.amountBody}>

      {maxDrawByBet > 1 &&
        <View>
          <Text style={style.stepTitle}>
            {t('Number of draws')}:
          </Text>
          <View style={style.nbDrawContainer}>
              <TouchableOpacity onPress={() => setNbDrawSelected(nbDrawSelected === 1 ? nbDrawSelected : nbDrawSelected - 1)} style={style.selectionNbBtnLess} underlayColor="#2655F9">
                <View style={style.nbDraw}>
                <SVGLess />
                </View>
              </TouchableOpacity>
              <View>
                <Text style={style.nbDrawSelected}>
                  {nbDrawSelected}
                </Text>
              </View>
              <TouchableOpacity onPress={() => setNbDrawSelected(nbDrawSelected === maxDrawByBet ? nbDrawSelected : nbDrawSelected + 1)} style={style.selectionNbBtn} underlayColor="#2655F9">
                <View style={style.nbDraw}>
                  <SVGAdd />
                </View>
              </TouchableOpacity>
          </View>
        </View>     
      }
      
    </View>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  amountBody: {
    marginTop: 10,
  },
  amountContainer:{
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  customAmount: {
    height: 50,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepTitle: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 18,
    marginTop: 40,
    marginBottom: 20,
    color: '#404040',
  },
  nbDrawContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30
  },
  selectionNbBtn:{
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginHorizontal: 25,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionNbBtnLess:{
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 25,
    paddingVertical: 0
  },
  nbDraw: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nbDrawSelected: {
    fontFamily: 'Raleway-Bold',
    fontSize: 28,
    color: theme.colors.primary
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  betTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: theme.colors.greyText,
  },
})
