import React from 'react';
import MySpace from "./MySpace"
import UserFavorites from "./UserFavorites"

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { styleStackScreenOptions } from '../../common/theme/theme';

const Stack = createNativeStackNavigator();


const MySpaceNavigation = () => {

  const { t } = useTranslation("translations");

  return (
    <Stack.Navigator
      initialRouteName="MySpaceHome"
      screenOptions={{...styleStackScreenOptions}}
    >
      <Stack.Screen name="MySpaceHome" component={MySpace} options={{ title: t('my space') }} />
      <Stack.Screen name="UserFavorites" component={UserFavorites} options={{ title: t('my favorites') }} />
    </Stack.Navigator>
  )
}

export default MySpaceNavigation; 