import { useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';

export default function BetWithAdditionnal(props) {
  
  const theme = useTheme();
  const style = makeStyles(theme);

  const {
    selectedBetType,
    styles,
    playerInfos,
    manipulatePlayerInfos,
    erasePlayerInfos,
    t,
    selectedPrice,
    currency
  } = props;

  //====================================== Functions

  function isDisabled(grid, numberPress) {
    return playerInfos[grid.gridName].requiredNumber === playerInfos[grid.gridName].playerNumber.length
      && playerInfos[grid.gridName].maxAdditional === playerInfos[grid.gridName].playerNumberAdditional.length
      && !numberPress;
  }

  function computeNumberPickClass(grid, numb, gridIndex) {
    if (gridIndex % 2 !== 0 && playerInfos[grid.gridName].playerNumber.includes(numb)) {
      return styles.oddPickNumberPressed;
    } else if (playerInfos[grid.gridName].playerNumberAdditional.includes(numb)) {
      return styles.AdditionalPickNumberPressed;
    } else if (playerInfos[grid.gridName].playerNumber.includes(numb)) {
      return styles.pickNumberPressed;
    } else {
      return styles.pickNumber;
    }
  }

  function computeNumberTextClass(grid, numb, gridIndex) {
    if ((gridIndex % 2 !== 0 && playerInfos[grid.gridName].playerNumber.includes(numb))
      || playerInfos[grid.gridName].playerNumberAdditional.includes(numb)
      || playerInfos[grid.gridName].playerNumber.includes(numb)) {
      return styles.numberPressed;
    } else {
      return styles.number;
    }
  }
  
  return (
    <View>

      {/* Grid Selection Part */}

      {selectedBetType?.listOfGrids.map((grid, gridIndex) => {
        return (
          <View key={gridIndex}>
            {playerInfos[grid.gridName].requiredNumber === playerInfos[grid.gridName].playerNumber.length ? 
              <Text style={styles.stepTitle}>
                Ajoutez jusqu'à {playerInfos[grid.gridName].maxAdditional - playerInfos[grid.gridName].playerNumberAdditional.length} numéros additionnels et multipliez vos chances de gagner
              </Text>
             :
              <Text style={styles.stepTitle}>
                {t('Select')} {playerInfos[grid.gridName].requiredNumber - playerInfos[grid.gridName].playerNumber.length} {t('numbers')}
              </Text>
          }

            <View style={style.betAmount}>
              <Text style={style.betTitle}>
              {t('Bet')} : {selectedPrice} {currency}
              </Text>
            </View>

            <View style={styles.grid}>
              {selectedBetType?.listOfGrids.length > 1 && 
                <Text style={styles.gridTitle}>
                  {grid.gridName}
                </Text>              
              }

              <View style={styles.gridPicker}>  
                {grid.listOfLottoNumbers.map((numb, index) => {
                  const [numberPress, setNumberPress] = useState(false);
                  return (
                    <Pressable
                      key={index}
                      onPress={() => {
                        setNumberPress(!numberPress);
                        manipulatePlayerInfos(numb, grid.gridName);
                      }}
                      disabled={isDisabled(grid, numberPress)}
                      style={computeNumberPickClass(grid, numb, gridIndex)}
                    >
                      <Text style={computeNumberTextClass(grid, numb, gridIndex)}>
                        {numb}
                      </Text>
                    </Pressable>
                  )
                })}
              </View>

              {playerInfos[grid.gridName].requiredNumber > 1 && 
                (
                  <Pressable 
                    onPress={() => {
                      erasePlayerInfos(grid.gridName);
                    }}
                    style={styles.eraseButton}
                  >
                    <Text style={styles.textEraseButton}>
                    {t('Delete')}
                    </Text>
                  </Pressable>
              )}
            </View>
          </View>              
        )
      })}

    </View>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingVertical: 18,
    paddingHorizontal: 15,
    flex: 1,
    marginHorizontal: 25
  },
  amountBody: {
    marginTop: 10,
  },
  amountContainer:{
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  customAmount: {
    height: 50,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  pickNumber: {
    height: 50,
    width: 'auto',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  pickNumberPressed: {
    height: 50,
    width: 'auto',
    borderRadius: 5,
    backgroundColor: theme.colors.primary,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  number: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.colors.greyText
  },
  numberPressed: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 18,
    color: '#ffffff'
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 40,
    marginBottom: 20,
    color: theme.colors.greyText,
  },
  nbDrawContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30
  },
  selectionNbBtn:{
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginHorizontal: 25,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionNbBtnLess:{
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 25,
    paddingVertical: 0
  },
  nbDraw: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nbDrawSelected: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 28,
    color: theme.colors.primary
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  recapTitle: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  betTitle: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 13,
    color: theme.colors.greyText,
  },
  recapValue:{
    width: '50%',
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  validateButton: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    marginBottom: 10
  },
  disabledValidateButton: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    opacity: 0.6,
    marginBottom: 10
  },
  textButton: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 15
  },
  betAmount: {
    backgroundColor: '#FFFFFF',
    width: '90%',
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 37,
    justifyContent: 'center',
    paddingLeft: 15
  },
  //Modal
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    width: '70%',
    backgroundColor: "white",
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 35,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  closeModal: {
    top: 5,
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    color: theme.colors.greyText,
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    color: theme.colors.greyText,
    marginBottom: 15,
    marginTop: 15,
    textAlign: "center"
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  amountInput: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 20,
    borderBottomColor: theme.colors.greyText,
    borderBottomWidth: 1,
    width: '90%',
    marginBottom: 30
  }
})
