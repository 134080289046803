import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TicketSummary from '../../components/ticket/TicketSummary';
import BetDetails from '../../components/ticket/BetDetails';

const TicketPage = () => {

  const { t } = useTranslation("translations");
  const style = makeStyles(useTheme());

  const [gameName, setGameName] = useState('');
  const [currency, setCurrency] = useState('');

  const ticket = useSelector(state => state.ticket.currentTicket);
  const games = useSelector(state => state.games.games);

  useEffect(() => {
    setGameName(games.find(game => game.gameId === ticket.listOfBets[0].gameId).name)
    setCurrency(ticket.currencySymbol)
  }, [ticket, games])

  return (
    <ScrollView>
      {ticket &&
      <View style={style.container}>
        <TicketSummary ticket={ticket} t={t} gameName={gameName} currency={currency} />
        {ticket.listOfBets?.map((bet, index) => (
            <BetDetails key={index} index={index} betDetails={bet} currency={currency} />
          ))
        }
      </View>
      }
    </ScrollView>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 15,
  }
});

//======================================
export default TicketPage;