import * as React from "react"
import Svg, { Rect } from "react-native-svg"

const SVGLaUne = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
    <Rect x={5.42} y={5.47} width={13.94} height={13.94} rx={0.6} ry={0.6} />
    <Rect x={20.64} y={5.47} width={13.94} height={13.94} rx={0.6} ry={0.6} />
    <Rect x={5.42} y={20.59} width={13.94} height={13.94} rx={0.6} ry={0.6} />
    <Rect x={20.64} y={20.59} width={13.94} height={13.94} rx={0.6} ry={0.6} />
  </Svg>
)

export default SVGLaUne