import React from 'react';
import Games from "./Games.page"

import IncomingDrawListScreen from './IncomingDrawListScreen';
import MutualListScreen from './MutualListScreen';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { styleStackScreenOptions } from '../../common/theme/theme';
import screens from '../../common/constants/screen.constants';

const Stack = createNativeStackNavigator();


const GamesNavigation = () => {

  const { t } = useTranslation("translations");

  const gamesScreenOptions = () => ({
    title: t('Next draws'),
  });

  return (
    <Stack.Navigator
      initialRouteName="GamesHome"
      screenOptions={{ ...styleStackScreenOptions }}
    >

      {/* Games Listing */}
      <Stack.Screen name="GamesHome" component={Games} options={{ title: t('games') }} />
      <Stack.Screen name={screens.IncomingDrawListScreen} component={IncomingDrawListScreen} options={gamesScreenOptions}/>
      <Stack.Screen name={screens.MutualListScreen} component={MutualListScreen} options={gamesScreenOptions} />

    </Stack.Navigator>
  )
}

export default GamesNavigation; 