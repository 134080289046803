import React from 'react';
import { View, Text, StyleSheet, ImageBackground, Button } from 'react-native';

import KpButton from './Button';

const style = StyleSheet.create({
  banner: {
    marginTop: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    height: 130,
    width: '100%',
    backgroundColor: '#ffffff',
  },
  image: {
    flex: 1,
    justifyContent: "center",
    resizeMode: 'cover',
  },
  sectionTitle: {
    fontSize: 18,
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
  },
  leftTopSection: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'column',
    width: '50%',
    justifyContent: 'space-around',    
  },
  leftBottomSection: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'column',
    width: '45%',
    justifyContent: 'space-around',       
  }
});

const GraphQlBanner = props => {
  const { t, title } = props;
  return (
    <View style={style.banner}>
      <ImageBackground imageStyle={{ borderRadius: 10}} source={require('../../assets/images/Game_Default_Thumnail.png')} resizeMode="cover" style={style.image}>
        <View style={style.leftTopSection}>
          <Text style={style.sectionTitle}>{title}</Text>
        </View>
        <View style={style.leftBottomSection}>
        <KpButton title={t("Discover")} />
        </View>
      </ImageBackground>
    </View>
  );
};

export default GraphQlBanner;