import { INCREMENT, DECREMENT, UPDATE_QUOTE } from '../actions/hello.actions';

// initial state of this reducer

const initialState = {
  counter: 0,
  quote: ''
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function helloReducer(state = initialState, action) {
    switch (action.type) {
      case INCREMENT:
        return  { ...state, counter: state.counter + 1 }
      case DECREMENT:
        return { ...state, counter: state.counter - 1 }
      case UPDATE_QUOTE:
        return { ...state, quote: action.payload }
      default:
        return state
    }
  }