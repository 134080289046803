import { ACTIONS, BET_STEPS } from '../actions/betCreator.actions';

// initial state of this reducer

const initialState = {
  selectedStake: undefined,
  selectedSessions: {}, // {drawId: drawObj}
  selectedNumbers: {}, // {grid1: [numbers]}
  selectedConsecutiveDraws: 1,
  selectedBetType: undefined,
}

// Reducer to catch the actions

export default function gamesReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.BETCREATOR_SESSIONS_SELECTED: {
      return { ...state, selectedSessions: action.payload }
    }
    case ACTIONS.BETCREATOR_BETTYPE_SELECTED: {
      return { ...state, selectedBetType: action.payload }
    }
    case ACTIONS.BETCREATOR_NUMBERS_SELECTED: {
      return { ...state, selectedNumbers: action.payload }
    }
    case ACTIONS.BETCREATOR_STAKE_SELECTED: {
      return { ...state, selectedStake: action.payload }
    }
    case ACTIONS.BETCREATOR_CONSECUTIVE_DRAWS: {
      return { ...state, selectedConsecutiveDraws: action.payload }
    }
    case ACTIONS.BETCREATOR_RESET: {
      return { ...initialState }
    }
    default:
      return state
  }
}