import { apiWebPlayerConfig } from '../../../app.json';
import { fillWebPlayerSecurityParams, wp_axios } from './api.webplayer.service';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;
const API_URL_v2c = API_URL.replace("/v2/", "/v2-c/");

export const getTicket = async (reference) => {
  let params = {};
  await fillWebPlayerSecurityParams(params);
  return wp_axios.get(`${API_URL_v2c}/tickets/${reference}`, { params });
}

//=============================================================================
// HELPERS
//=============================================================================
