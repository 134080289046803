import React from 'react';
import { View, StyleSheet } from 'react-native';

import DrawGameList from '../../components/games/DrawGameList';

import { useTranslation } from 'react-i18next';

const IncomingDrawListScreen = (props) => {

  const { t } = useTranslation("translations");
  const { route } = props;

    return (
      <View style={style.container}>
        <DrawGameList sessions={route.params.sessions}/>
      </View>
    );
}

const style = StyleSheet.create({
  container: {
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 20,
    flex: 1,
    flexDirection: 'column',
    color: '#F6F6F6'
  }
})
export default IncomingDrawListScreen; 