import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Keyboard, Modal, Pressable, View, Text, TextInput, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SVGInformationCircle } from '../../assets/icons';
import screens from '../../common/constants/screen.constants';
import ScanDialog from '../../components/scan/ScanDialog.comp';
import * as EBulletionActions from '../../store/actions/ebulletin.actions';
import * as TicketActions from '../../store/actions/ticket.actions';
import * as GamesActions from '../../store/actions/games.actions';
import { isEbulletinFormat } from '../../store/services/ebulletin.service';

const ScanManualPage = ({ route }) => {

  const { t } = useTranslation("translations");
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const style = makeStyles(useTheme())


  const [manualEntry, setManualEntry] = useState('');

  const errorBulletin = useSelector(state => state.ebulletin.error);
  const errorTicket = useSelector(state => state.ticket.error);
  const isFetching = useSelector(state => state.ebulletin.isFetching || state.ticket.isFetching);

  // HOOKS

  useFocusEffect(
    React.useCallback(() => {

      return () => {
        resetSearchState();
      }
    }, [])
  );

  // FUNCTIONS

  const onValidate = () => {
    Keyboard.dismiss();
    console.log("onValidate, entry = " + manualEntry);
    dispatch(GamesActions.getGames());
    if (!manualEntry) return;

    if (isEbulletinFormat(manualEntry)) {
      dispatch(EBulletionActions.searchBulletin(manualEntry));
    } else {
      dispatch(TicketActions.searchTicket(manualEntry));
    }
  }

  const onHelp = () => {
    navigation.navigate(screens.ScanHelp);
  }

  const resetSearchState = () => {
    dispatch(EBulletionActions.reset_search_state());
    dispatch(TicketActions.reset_search_state());
  }

  const goQRCodeScan = () => {
    navigation.navigate(screens.ScanPreview);
  }

  return (
    <View style={style.container}>

      {isFetching == false && <>

        <View style={style.text_scan_label_block}>
          <Text style={style.text_scan_label}>
            {t('scan.manual_entry_label')}
          </Text>
          <Text style={style.text_scan_label}>
            {t('scan.manual_entry_label_2')}
          </Text>
        </View>

        <TextInput
          style={style.input}
          value={manualEntry}
          onChangeText={setManualEntry}
          placeholder={t('scan.manual_placeholder')}
          keyboardType="default"
        />


        <Pressable
          style={[style.button_validate, manualEntry?.length > 0 ? undefined : style.disabled]}
          onPress={onValidate}
        >
          <Text style={style.button_validate_text}>
            {t('validate')}
          </Text>
        </Pressable>

        <Pressable
          style={style.help}
          onPress={onHelp}
        >
          <SVGInformationCircle />
          <Text style={style.help_text}>
            {t('scan.find_code')}
          </Text>
        </Pressable>

      </>}

      {
        isFetching &&
        <View style={style.search_in_progress}>
          <ActivityIndicator size="large" color='gray' />
          <Text>{t('search_in_progress')}</Text>
        </View>
      }

      <Modal animationType="slide" transparent={true} visible={Boolean(errorTicket || errorBulletin)}>
        <ScanDialog
          message={t('ticket.ref_not_found')}
          subMessage={manualEntry ? t('ref') + manualEntry : undefined}
          mainAction={() => resetSearchState()}
          mainActionTitle={t('retry')}
          alternativeAction={goQRCodeScan}
          alternativeActionTitle={t('scan.dialog_action_qrcode')}
          onClose={() => resetSearchState()}
        />
      </Modal>

    </View>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 50,
  },
  disabled: {
    opacity: .5,
  },
  button_validate: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10,
    margin: 20,
    borderRadius: 4,
    elevation: 6,
    backgroundColor: theme.colors.primary,
  },
  button_validate_text: {
    fontSize: 16,
    color: 'white',
  },
  input: {
    backgroundColor: 'white',
    fontSize: 16,
    textAlign: 'center',
    color: "#444",
    paddingVertical: 8,
  },
  search_in_progress: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: -20,
  },
  text_scan_label_block: {
    paddingTop: 30,
    paddingBottom: 30
  },
  text_scan_label: {
    textAlign: 'center',
    fontWeight: '600',
    color: "#444",
    fontSize: 17,
  },
  help: {
    marginVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  help_text: {
    color: '#222',
    paddingLeft: 10,
  }
})

//======================================
export default ScanManualPage;