import axios from 'axios';
// import { sha256 } from 'react-native-sha256';
import { sha256 } from '../../utils/CryptoUtils';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { _APP_VERSION } from '../../common/version.json'
import { apiWebPlayerConfig } from '../../../app.json';
import i18n from '../../common/i18n/i18n';

const API_TIMEOUT = 60000;

const createWebPlayerAxios = () => {
  let instance = axios.create({
    timeout: API_TIMEOUT,
  });

  instance.interceptors.response.use(undefined, err => {
    console.error("WebPlayer Axios error : " + (typeof err === 'object' ? JSON.stringify(err) : err));
    //TODO: manage session/token expiration (detect, request new keycloak token & webplayer session, re-run the request)
    // bubble up the error
    return Promise.reject(err);
  });

  return instance;
}
export const wp_axios = createWebPlayerAxios();

let wp_token = '';
let wp_nonce = new Date().getTime();

export const setWebPlayerAuthKey = (keycloak_token) => {
  wp_axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak_token}`;
}

export const createWebPlayerSession = async () => {
  try {
    //ASSERT: the keycloak token is already defined and up to date (into axios config)

    //create session
    let lang2 = i18n.language;
    let apiLanguage = lang2 == 'fr' ? 'fra' : lang2 == 'en' ? 'eng' : 'por';

    let wp_session_data = {
      application: "Player Sale Point Application",
      applicationVersion: _APP_VERSION,
      language: apiLanguage,
    };
    let res = await wp_axios.post(`${apiWebPlayerConfig.UrlService}/session/create`, wp_session_data);
    if (!res?.data) {
      throw new Error("api session create: empty response");
    }

    // store the webplayer token
    wp_token = res.data.token;
    console.log("getSession wp token : " + wp_token);
    await AsyncStorage.setItem("wp_token", wp_token);

    // return the session object
    return res.data;
  }
  catch (err) {
    console.error('createWebplayerSession failed', err);
    //TODO: manage app updates
    switch (err.errorId) {
      case 301100: // API_APP_UNKNOWN - The application is not registered
      case 301101: // API_APP_VERSION_UNKNOWN - The application version is not registered
      case 301102: // API_APP_VERSION_FORBIDDEN - The application version is not authorized
        // await this.appUpdate.check(LoginInformation.ApplicationVersionStatusEnum.FORBIDDEN, err.hint);
        break;
      default:
        throw err;
    }
  }
}

export const fillWebPlayerSecurityParams = async (params) => {
  if (!wp_token) {
    wp_token = await AsyncStorage.getItem('wp_token');
  }
  params.nonce = ++wp_nonce;
  params.token = wp_token;
  const hashdata = wp_token + apiWebPlayerConfig.apiPrivateKey + wp_nonce;
  params.hash = await sha256(hashdata);
}
