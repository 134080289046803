export const translationFR = {
    /*eslint-disable */

    /** ============================================ GENERAL DICTIONARY  ========================================*/
    "back": "Retour",
    "games": "Jeux",
    "go_back_home": "Retour à l'accueil",
    "loading": "Chargement...",
    "loading_data": "Chargement des données...",
    "lotteries": "Loteries",
    "no": "Non",
    "ref": "Réf.",
    "results": "Résultats",
    "retry": "Réessayer",
    "today": "Aujourd'hui",
    "today_at": "Aujourd'hui à",
    "search_in_progress": "Recherche en cours...",
    "validate": "Valider",
    "yes": "Oui",
    "Next draws": "Prochains tirages",
    /** =========================================================================================================*/

    /** ------------------------------------------------ BET CREATION ----------------------------------------------*/
    "end_of_bets": "Fin des paris",
    "game_rules": "Règles du jeu",
    "minute": "minute",
    "minutes": "minutes",
    "next_draw": "Prochain tirage",
    "next_draw_less": "Prochain tirage dans moins de",
    "no_draw_available": "Aucun tirage n'est disponible pour le moment. Réessayez plus tard.",
    "select_bet_type": "Choisis un type de jeu",
    "select_game_session": "Choisis une session de jeu",
    "select_game_sessions": "Choisis une ou plusieurs sessions de jeu",
    "Validate": "Valider",
    "Delete": "Effacer",
    "Remaining": "Restant",
    "Stake per bet": "Mise par pari",
    "Number of draws": "Nombres de tirages",
    "Summary": "Récapitulatif",
    "Total bet": "Mise totale",
    'additional numbers': 'numéros additionnels',
    "Custom amount": "Montant personnalisé",
    "Select amount": "Selectionnez votre montant",
    "Select between": "Selectionnez un montant entre",
    "And": "et",

    /** ------------------------------------------------ ERRORS ----------------------------------------------*/
    "error.generic": "Une erreur est survenue.",
    "Something went wrong": "Quelque chose ne va pas",
    "The app takes too long to start": "L’appli met trop longtemps à démarrer",

    /** ------------------------------------------------ E-BULLETIN ----------------------------------------------*/
    "ebulletin.tag": "E-Bulletin",

    /** ------------------------------------------------ SCAN ----------------------------------------------*/
    "Manual entry": "Saisie manuelle",
    "scan.button_qrcode": "Je scanne un QR code",
    "scan.button_code": "Je saisis mon code",
    "scan.dialog_action_manual_entry": "Saisir le code manuellement",
    "scan.dialog_action_qrcode": "Scanner un QR code",
    "scan.dialog_error_qrcode": "Nous n'avons pas pu lire votre QR code.",
    "scan.find_code": "Où trouver mon code ?",
    "scan.help_bulletin_1": "Rendez-vous dans ‟Mes Paris”",
    "scan.help_bulletin_2": "Cliquez sur ‟E-Bulletins”",
    "scan.help_bulletin_3": "Ouvrez le bulletin de votre choix en cliquant sur ‟Voir”",
    "scan.help_bulletin_4": "Le code est situé sous le QR code",
    "scan.help_bulletin_title": "Saisissez le code d'un e-bulletin pour charger le même pari sur votre téléphone, ou partagez votre e-bulletin avec un ami.",
    "scan.help_ticket_title": "Saisissez le code situé sur votre reçu, juste au dessus du QR code.",
    "scan.manual_entry_label": "Saisissez votre code",
    "scan.manual_entry_label_2": "(ticket ou e-bulletin)",
    "scan.manual_placeholder": "Saisissez le code",
    "scan.scan_not_available": "La caméra n'est pas disponible. Merci de vérifier les autorisations sur votre smartphone.",
    "scan.scan_ticket_or_bulletin": "Scannez un ticket ou un e-bulletin",
    "Scan unavailable": "Disponible uniquement sur l'application Android ou IOS.",

    /** ------------------------------------------------ TABS ----------------------------------------------*/
    "my bets": "Mes paris",
    "my favorites": "Mes favoris",
    "my space": "Mon espace",
    "tab scan": "Scan",

    /** ------------------------------------------------ SCAN ----------------------------------------------*/
    "Manual entry": "Saisie manuelle",
    "scan.button_qrcode": "Je scanne un QR code",
    "scan.button_code": "Je saisis mon code",
    "scan.find_code": "Où trouver mon code ?",
    "scan.manual_entry_label": "Saisissez votre code",
    "scan.manual_entry_label_2": "(ticket ou e-bulletin)",
    "scan.manual_placeholder": "Saisissez le code",
    "scan.scan_ticket_or_bulletin": "Scannez un ticket ou un e-bulletin",

    /** ------------------------------------------------ GAMES ----------------------------------------------*/
    "Find a game": "Cherchez un jeu",
    "Discover": "Découvrir",
    "See all": "Voir tout",
    /** ------------------------------------------------ TICKET ----------------------------------------------*/
    "ticket.ref_not_found": "Nous n'avons pas trouvé votre ticket.",
    "Ticket": "Ticket",
    "Status": "Statut",
    "Total bet": "Mise totale",
    "Draws made": "Tirages effectués",
    "Draws pending": "Tirages restants",
    "Total gains": "Gains cumulés",

    "Game type": "Type de jeu",
    "Bet": "Mise",
    "Gains": "Gains",
    "Draw": "tirage",
    "Selection": "Sélection",

    "Expired": "Expiré",
    "Lost": "Perdu",
    "Won": "Gagné",
    "Pending": "En cours",
    "Finished": "Terminé",
    "Canceled": "Annulé",
    "Price": "Prix",
    
    "Draw results": "Résultats du tirage",
    "Your selection": "Votre sélection",
    "Winning combinations": "Combinaisons gagnantes",
    "Rank": "Rang",
}