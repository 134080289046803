import React, { useEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as GamesActions from '../../store/actions/games.actions';

import { useTranslation } from 'react-i18next';

// component import
import SearchInput from '../../components/kpLibrary/SearchInput';
import GraphQlBanner from '../../components/kpLibrary/GraphQlBanner';
import GameSearchFilter from '../../components/games/GameSearchFilter';
import PreviewGameList from '../../components/games/PreviewGameList';

const Games = ({ navigation }) => {

  const { t } = useTranslation("translations");
  const dispatch = useDispatch();

  const games = useSelector(state => state.games.games);
  const sessionsMutualFutureAll = useSelector(state => state.games.sessionsMutualFutureAll)
  const sessionsInstantFutureAll = useSelector(state => state.games.sessionsInstantFutureAll)

  useEffect(() => {
    games.length == 0 && dispatch(GamesActions.getGames());
  }, [])

  useEffect(() => {
    dispatch(GamesActions.getAllSessionsFuture());
  }, [])

    return (
      <ScrollView style={style.container}>
          <GraphQlBanner t={t} title='LES JACKPOTS DU MOIS'/>
          <SearchInput t={t} placeHolder='Find a game' />
          <GameSearchFilter style={style.list} />
          <PreviewGameList t={t} title='Next draws' sessions={sessionsInstantFutureAll.concat(sessionsMutualFutureAll)} navigationScreen={'IncomingDrawListScreen'}/>
          <PreviewGameList t={t} title='Jackpots' sessions={sessionsMutualFutureAll} navigationScreen={'MutualListScreen'}/>
      </ScrollView>
    );
}

const style = StyleSheet.create({
  container: {
    marginLeft: 10,
    marginRight: 10,
    flex: 1,
    flexDirection: 'column',
    color: '#F6F6F6'
  },
  list:{
    height: 60
  }
})

export default Games; 