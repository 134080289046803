import React, { useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { Modal, StyleSheet, Text, View } from 'react-native';

const PopUpModal = ({modalVisible, setModalVisible, message}) => {

  const styles = makeStyles(useTheme());

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onShow={() => setTimeout(() => {
            setModalVisible(false)
          }, 4000)}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{message}</Text>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 45,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '95%'
  },
  button: {
    borderRadius: 20,
    elevation: 2,
    width: '100%',
    alignItems: 'end',
    marginBottom: 5
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    textAlign: 'center',
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold'
  },
});

export default PopUpModal;