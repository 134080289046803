import moment from "moment";


export function dateFirstFormat(value) {
    if (!value) {
        return ""
    }
    return moment(value).format("dddd DD/MM");
}

export function dateSecondFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format("dddd DD/MM, HH:mm");
}

export function ticketDateFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format('LLLL');;
}

export function drawDateFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format('DD/MM HH:mm');
}
