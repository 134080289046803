import React from 'react';
import { View, Text, StyleSheet, Button } from 'react-native';

const style = StyleSheet.create({
  container: {
      padding: 20,
  },
})

const UserFavorites = ({ navigation, route }) => {

  const onPressNavigate = () => {
    navigation.navigate('Games');
  }

  return (
    <View style={style.container}>
      <Text>User Favorites nested page (test)</Text>
      <Text>Param ID={route?.params?.id}</Text>
      <Button title="Navigate to 'Games'" onPress={onPressNavigate} />
    </View>
  )

}

export default UserFavorites; 