import { apiWebPlayerConfig } from '../../../app.json';
import { fillWebPlayerSecurityParams, wp_axios } from './api.webplayer.service';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;

export const getBulletin = async (code) => {
  let params = {};
  await fillWebPlayerSecurityParams(params);
  return wp_axios.patch(`${API_URL}/players/bulletins/${code}/load`, null, { params });
}

//=============================================================================
// HELPERS
//=============================================================================

const Regex_Ebulletin_Code = /^[0-9]{3}[A-F][0-9]{3}$/;

export const isEbulletinFormat = (code) => {
  return code.match(Regex_Ebulletin_Code);
}